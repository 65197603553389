import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAndroidInvitationPayload } from '../../../components/Enrollments/definitions';
import { HTTP_STATUS_CODE } from '../../../constants';
import {
  IDevice,
  IDeviceDeletePayload,
  IDeviceGroupUpdatePayload,
} from '../../../pages/AndroidDevices/definitions';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  ANDROID_DEVICES_LIST_ROUTE,
  ANDROID_DEVICES_SYNC_ROUTE,
  ANDROID_DEVICE_ENROLLMENT_ROUTE,
  ANDROID_DEVICE_REMOVE_ROUTE,
  ANDROID_DEVICE_RESTRICTION_RETRY_MFA,
  LINK_DEVICE_WITH_DEVICE_GROUP_ROUTE,
} from '../../../utils/routes-defs';
import { RootState } from '../../store';

interface IAndroidDeviceSliceState {
  androidDevices: IDevice[];
  loading: boolean;
  message: IMessage;
  page: number;
  limit: number;
  total: number;
  isAll: boolean;
}

const initialState: IAndroidDeviceSliceState = {
  androidDevices: [],
  loading: false,
  message: messageInit,
  page: 1,
  limit: 25,
  total: 0,
  isAll: false,
};

export const getAndroidDevices = createAsyncThunk(
  'androidDevice/getAndroidDevices',
  async (_, thunkAPI) => {
    try {
      const { getState } = thunkAPI;
      const { page, limit, isAll } = (getState() as RootState).androidDevice;

      const response = await axiosInstance().get(
        `${ANDROID_DEVICES_LIST_ROUTE}?page=${page}&limit=${limit}&all=${isAll}`,
      );
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const sendEnrollmentInvitaion = createAsyncThunk(
  'androidDevice/sendEnrollmentInvitaion',
  async (payload: IAndroidInvitationPayload, thunkAPI) => {
    try {
      if (payload.sendEmail) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: 'Enrollment email sent successfully',
          }),
        );
      }
      const response = await axiosInstance().post(ANDROID_DEVICE_ENROLLMENT_ROUTE, payload);
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const syncDeviceList = createAsyncThunk(
  'androidDevice/syncDeviceList',
  async (payload: { enterpriseId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_DEVICES_SYNC_ROUTE, payload);
      thunkAPI.dispatch(getAndroidDevices());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const updateDeviceGroup = createAsyncThunk(
  'androidDevice/updateDeviceGroup',
  async (payload: IDeviceGroupUpdatePayload, thunkAPI) => {
    try {
      const response = await axiosInstance().post(LINK_DEVICE_WITH_DEVICE_GROUP_ROUTE, payload);
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const deleteDevice = createAsyncThunk(
  'androidDevice/deleteDevice',
  async (payload: IDeviceDeletePayload, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_DEVICE_REMOVE_ROUTE, payload);
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const sendMFA = createAsyncThunk(
  'androidDevice/sendMFA',
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  async (payload: any, thunkAPI) => {
    try {
      const response = await axiosInstance().post(ANDROID_DEVICE_RESTRICTION_RETRY_MFA, payload);
      thunkAPI.dispatch(getAndroidDevices());
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

const androidDeviceSlice = createSlice({
  name: 'androidDevice',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setAll: (state, action) => {
      state.isAll = action.payload;
    },
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
    updateTotalDevicesCount: (state, action) => {
      state.total = action.payload;
    },
    updateAndroidDevicesArray: (state, action) => {
      state.androidDevices = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get android devices
    builder.addCase(getAndroidDevices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAndroidDevices.fulfilled, (state, action) => {
      const { devices, message, total } = action.payload;
      const allAndroidDevices = devices.map((device: any, idx: number) => ({
        id: (state.page - 1) * state.limit + idx + 1,
        ...device,
      }));

      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
      state.total = total;
      state.loading = false;
      state.androidDevices = allAndroidDevices;
    });
    builder.addCase(getAndroidDevices.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // send enrollment invitation
    builder.addCase(sendEnrollmentInvitaion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEnrollmentInvitaion.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendEnrollmentInvitaion.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // sync android device list
    builder.addCase(syncDeviceList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(syncDeviceList.fulfilled, (state, action) => {
      const responseMessage = action.payload.message;

      state.loading = false;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage as string,
      };
    });
    builder.addCase(syncDeviceList.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // update android device group
    builder.addCase(updateDeviceGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDeviceGroup.fulfilled, (state, action) => {
      const { device, message } = action.payload;
      const updatedDevice = device;
      const updatedDevicesArray = state.androidDevices.map((device: IDevice) => {
        if (device._id === updatedDevice._id) {
          return {
            ...device,
            deviceGroup: updatedDevice.deviceGroupId?.name,
            deviceGroupId: updatedDevice.deviceGroupId?._id,
          };
        }
        return device;
      });

      state.loading = false;
      state.androidDevices = updatedDevicesArray;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
    });
    builder.addCase(updateDeviceGroup.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // delete device entry
    builder.addCase(deleteDevice.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDevice.fulfilled, (state, action) => {
      const { message } = action.payload;
      const { deviceName } = action.meta.arg;
      const updatedTableData = state.androidDevices
        .filter((item: IDevice) => item.name !== deviceName)
        .map((device: IDevice, idx: number) => ({
          ...device,
          id: (state.page - 1) * state.limit + idx + 1,
        }));

      state.loading = false;
      state.total -= 1;
      state.androidDevices = updatedTableData;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
    });
    builder.addCase(deleteDevice.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });

    // MFA resend
    builder.addCase(sendMFA.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(sendMFA.fulfilled, (state, action) => {
      const { message } = action.payload;

      state.loading = false;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
    });
    builder.addCase(sendMFA.rejected, (state, action) => {
      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: action.payload as string,
      };
    });
  },
});

export const {
  updateMessage,
  setPage,
  setLimit,
  setAll,
  updateTotalDevicesCount,
  updateAndroidDevicesArray,
} = androidDeviceSlice.actions;
export default androidDeviceSlice;
