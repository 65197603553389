import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ANDROID_DEVICE_CONST } from '../../../constants';
import DeviceApprovalStagesEnum from '../../../enums';
import {
  deleteDevice,
  sendMFA,
  updateMessage,
} from '../../../redux/slices/androidDevice/androidDeviceSlice';
import { AppDispatch } from '../../../redux/store';
import axiosInstance from '../../../utils/axiosInstance';
import { messageInit } from '../../../utils/common-constants';
import { ANDROID_DEVICE_COMMAND_ISSUE_ROUTE } from '../../../utils/routes-defs';
import {
  IDeviceDeletePayload,
  IIssueCommandEnum,
  IIssueCommandPayload,
  WipeDataFlagsEnum,
} from '../definitions';
import ClearAppsDataDialog from './ClearAppsDataDialog';
import ResetDevicePasswordDialog from './ResetDevicePasswordDialog';
import StartDeviceLostModeDialog from './startDeviceLostModeDialog';
import {
  DEVICE_DEPROVISION_INFORMATION,
  DEVICE_DEPROVISION_USER_FACING_MESSAGE,
} from '../constants';
import DeprovisionDialog from './DeprovisionDialog';
import { Tooltip } from '../../AndroidPolicies/components/InfoTooltip';

interface IProps {
  deviceName: string;
  anchorEl: HTMLElement | null;
  handleMenu: () => void;
  policyName: string;
  deviceManagementMode: string;
  deviceApprovalStatus?: string;
}

function CommandsMenu(props: IProps) {
  const {
    deviceName,
    anchorEl,
    handleMenu,
    policyName,
    deviceManagementMode,
    deviceApprovalStatus,
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [openClearAppsDataDialog, setOpenClearAppsDataDialog] = useState<boolean>(false);
  const [openResetPasswordDialog, setResetPasswordDialog] = useState<boolean>(false);
  const [openStartLostModeDialog, setStartLostModedDialog] = useState<boolean>(false);
  const [openDeprovisionDialog, setDeprovisionDialog] = useState<boolean>(false);
  const [wipingMessage, setWipingMessage] = useState<string>(
    DEVICE_DEPROVISION_USER_FACING_MESSAGE,
  );

  const sendCommand = (payload: IIssueCommandPayload) => {
    if (
      payload.commandDetails.device_command === IIssueCommandEnum.START_LOST_MODE &&
      payload.commandDetails.startLostOptions?.emailAddress?.trim() === ''
    ) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Email is required',
        }),
      );
      return;
    }
    if (
      payload.commandDetails.device_command === IIssueCommandEnum.RESET_PASSWORD &&
      payload.commandDetails.newPassword?.trim() === ''
    ) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'New Password is required for password reset',
        }),
      );
      return;
    }

    axiosInstance()
      .post(ANDROID_DEVICE_COMMAND_ISSUE_ROUTE, payload)
      .then((response) => {
        dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: response.data,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: err.response.data.errors[0].message,
          }),
        );
      });
  };

  const handleCommandMenuItemClick = (command: IIssueCommandEnum) => {
    switch (command) {
      case IIssueCommandEnum.LOCK:
        handleMenu();
        sendCommand({
          deviceName,
          commandDetails: {
            device_command: IIssueCommandEnum.LOCK,
          },
        });
        break;

      case IIssueCommandEnum.CLEAR_APP_DATA:
        setOpenClearAppsDataDialog(true);
        break;

      case IIssueCommandEnum.REBOOT:
        handleMenu();
        sendCommand({
          deviceName,
          commandDetails: {
            device_command: IIssueCommandEnum.REBOOT,
          },
        });
        break;

      case IIssueCommandEnum.START_LOST_MODE:
        setStartLostModedDialog(true);
        break;

      case IIssueCommandEnum.STOP_LOST_MODE:
        handleMenu();
        sendCommand({
          deviceName,
          commandDetails: {
            device_command: IIssueCommandEnum.STOP_LOST_MODE,
          },
        });
        break;

      case IIssueCommandEnum.RESET_PASSWORD:
        setResetPasswordDialog(true);
        break;

      case IIssueCommandEnum.DEPROVISION:
        setDeprovisionDialog(true);
        break;

      default:
        break;
    }
  };

  const handleSendMFA = () => {
    const payload = {
      deviceName,
    };
    dispatch(sendMFA(payload));
  };

  const handleDeviceDeletion = (wipeFlags: WipeDataFlagsEnum[]) => {
    const payload: IDeviceDeletePayload = {
      deviceName,
      wipingMessage,
      wipeFlags,
    };
    dispatch(deleteDevice(payload));
  };

  return (
    <>
      <Menu
        id='account-menu'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenu}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={() => handleCommandMenuItemClick(IIssueCommandEnum.LOCK)}>
          Lock Device
        </MenuItem>
        <MenuItem onClick={() => handleCommandMenuItemClick(IIssueCommandEnum.CLEAR_APP_DATA)}>
          Clear Apps Data
        </MenuItem>
        {deviceManagementMode === ANDROID_DEVICE_CONST.COMPANY_OWNED && (
          <MenuItem onClick={() => handleCommandMenuItemClick(IIssueCommandEnum.REBOOT)}>
            Reboot Device
          </MenuItem>
        )}

        <MenuItem onClick={() => handleCommandMenuItemClick(IIssueCommandEnum.RESET_PASSWORD)}>
          {deviceManagementMode === ANDROID_DEVICE_CONST.COMPANY_OWNED
            ? 'Reset Device Password'
            : 'Reset Work Password'}
        </MenuItem>

        {deviceManagementMode === ANDROID_DEVICE_CONST.COMPANY_OWNED && (
          <MenuItem onClick={() => handleCommandMenuItemClick(IIssueCommandEnum.START_LOST_MODE)}>
            Start Device Lost Mode
          </MenuItem>
        )}
        {deviceManagementMode === ANDROID_DEVICE_CONST.COMPANY_OWNED && (
          <MenuItem onClick={() => handleCommandMenuItemClick(IIssueCommandEnum.STOP_LOST_MODE)}>
            Stop Device Lost Mode
          </MenuItem>
        )}
        {(deviceApprovalStatus === DeviceApprovalStagesEnum.mfa_requested ||
          deviceApprovalStatus === DeviceApprovalStagesEnum.mfa_denied) && (
          <MenuItem onClick={handleSendMFA}>Re-Initiate MFA</MenuItem>
        )}
        <MenuItem
          onClick={() => handleCommandMenuItemClick(IIssueCommandEnum.DEPROVISION)}
          sx={{ color: 'red' }}
        >
          <Tooltip title={DEVICE_DEPROVISION_INFORMATION} arrow>
            <span>Deprovision</span>
          </Tooltip>
        </MenuItem>
      </Menu>
      <ClearAppsDataDialog
        deviceName={deviceName}
        open={openClearAppsDataDialog}
        setOpen={setOpenClearAppsDataDialog}
        policyName={policyName}
        sendCommand={sendCommand}
      />
      <ResetDevicePasswordDialog
        deviceManagementMode={deviceManagementMode}
        deviceName={deviceName}
        open={openResetPasswordDialog}
        setOpen={setResetPasswordDialog}
        sendCommand={sendCommand}
      />
      <StartDeviceLostModeDialog
        deviceName={deviceName}
        open={openStartLostModeDialog}
        setOpen={setStartLostModedDialog}
        sendCommand={sendCommand}
      />
      <DeprovisionDialog
        deviceManagementMode={deviceManagementMode}
        wipingMessage={wipingMessage}
        open={openDeprovisionDialog}
        setOpen={setDeprovisionDialog}
        setWipingMessage={setWipingMessage}
        handleDeviceDeletion={handleDeviceDeletion}
      />
    </>
  );
}

CommandsMenu.defaultProps = {
  deviceApprovalStatus: null,
};

export default CommandsMenu;
