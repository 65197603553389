import { Button, Card, SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../../..';
import { IPolicyEnforcementRule } from '../../../../definitions';
import PropertyAndInput from '../../../PropertyAndInput';
import PropertyAndSelectInput from '../../../PropertyAndSelectInput';
import { ANDROID_POLICY_MSG } from '../../../../../../messages';

function ComplianceRule() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { policyEnforcementRules } = policy.securityPermissions;

  const handleChange = (event: SelectChangeEvent, index: number) => {
    const { name, value } = event.target;
    setPolicy((prev) => {
      const allEnforcementRules = prev.securityPermissions.policyEnforcementRules.map(
        (elem: IPolicyEnforcementRule, id) => {
          if (id === index) {
            let rule: IPolicyEnforcementRule = elem;
            if (name === 'settingName') {
              rule = {
                ...rule,
                [name]: value,
              };
            } else if (name === 'blockAfterDays' || name === 'blockScope') {
              rule = {
                ...rule,
                blockAction: {
                  ...rule.blockAction,
                  [name]: value,
                },
              };
            } else if (name === 'wipeAfterDays' || name === 'preserveFrp') {
              rule = {
                ...rule,
                wipeAction: {
                  ...rule.wipeAction,
                  [name]: value,
                },
              };
            }
            return rule;
          }
          return elem;
        },
      );

      return {
        ...prev,
        securityPermissions: {
          ...prev.securityPermissions,
          policyEnforcementRules: allEnforcementRules,
        },
      };
    });
  };

  const handleAddNewPolicyEnforcementRule = () => {
    setPolicy((prev) => {
      const newSetting: IPolicyEnforcementRule = {
        blockAction: {
          blockAfterDays: 0,
          blockScope: 'work_profile_block',
        },
        wipeAction: {
          wipeAfterDays: 2,
          preserveFrp: false,
        },
        settingName: '',
      };

      return {
        ...prev,
        securityPermissions: {
          ...prev.securityPermissions,
          policyEnforcementRules: [...policyEnforcementRules, newSetting],
        },
      };
    });
  };
  return (
    <>
      <Button variant='contained' onClick={handleAddNewPolicyEnforcementRule}>
        Add New Compliance Rule
      </Button>
      {policyEnforcementRules?.map((policyEnforcementRule, index) => {
        const isWipeAfterDaysError =
          policyEnforcementRule.wipeAction.wipeAfterDays <
          policyEnforcementRule.blockAction.blockAfterDays;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Card key={index} elevation={2} sx={{ padding: 2 }}>
            <PropertyAndSelectInput
              data={{
                property: 'Setting Name',
                name: 'settingName',
                infoText: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SETTING_NAME,
                menuItems: [
                  { view: 'Applications', value: 'applications' },
                  { view: 'Password Settings', value: 'passwordPolicies' },
                ],
                value: policyEnforcementRule.settingName,
                handleChange: (e: SelectChangeEvent) => handleChange(e, index),
              }}
            />
            <PropertyAndSelectInput
              data={{
                property: 'Block Scope',
                name: 'blockScope',
                infoText: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.BLOCK_SCOPE,
                menuItems: [
                  {
                    view: 'Work Profile',
                    value: 'work_profile_block',
                    tooltip: ANDROID_POLICY_MSG.COMMON_SCOPES.WORK_PROFILE,
                  },
                  {
                    view: 'Device Wide',
                    value: 'device_block',
                    tooltip: ANDROID_POLICY_MSG.COMMON_SCOPES.DEVICE_WIDE,
                  },
                ],
                value: policyEnforcementRule.blockAction.blockScope,
                handleChange: (e: SelectChangeEvent) => handleChange(e, index),
              }}
            />
            <PropertyAndInput
              property='Block After Days'
              name='blockAfterDays'
              type='number'
              infoText={ANDROID_POLICY_MSG.SECURITY_INFOTEXT.BLOCK_AFTER_DAYS}
              value={policyEnforcementRule.blockAction.blockAfterDays}
              onChange={(e: SelectChangeEvent) => handleChange(e, index)}
            />
            <PropertyAndInput
              property='Wipe After Days'
              name='wipeAfterDays'
              type='number'
              infoText={ANDROID_POLICY_MSG.SECURITY_INFOTEXT.WIPE_AFTER_DAYS}
              value={policyEnforcementRule.wipeAction.wipeAfterDays}
              onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              error={isWipeAfterDaysError}
              helperText={isWipeAfterDaysError ? 'Block days > Wipe days' : ''}
            />
          </Card>
        );
      })}
    </>
  );
}

export default ComplianceRule;
