import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import appleLogo from '../../assets/images/apple-logo.png';
import CustomHeader from '../../components/CustomHeader';
import CustomTable from '../../components/CustomTable';
import { ISelectItem } from '../../components/Enrollments/definitions';
import ErrorSuccessSnackbar from '../../components/ErrorSuccessSnackbar';
import { APPLE_DEVICE_CONST, APPLE_OS, EMPTY_STRING } from '../../constants';
import DeviceApprovalStagesEnum from '../../enums';
import MainLayout from '../../layouts/MainLayout';
import { ANDROID_DEVICE_MSG } from '../../messages';
import {
  getAppleDevices,
  setLimit,
  setPage,
  updateDeviceGroup,
  updateMessage,
} from '../../redux/slices/appleDevice/appleDeviceSlice';
import { getAppleDeviceGroups } from '../../redux/slices/appleDeviceGroup/appleDeviceGroupSlice';
import { getAppleEnrollmentDetails } from '../../redux/slices/appleEnrollment/appleEnrollmentSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { COLOR_BLACK, COLOR_WHITE } from '../../theme';
import { messageInit } from '../../utils/common-constants';
import { IDeviceGroupUpdatePayload } from '../AndroidDevices/definitions';
import { IAppleDeviceGroup } from '../AppleDeviceGroups';
import DeviceEnrollment from './DeviceEnrollment';
import CommandsMenu from './components/CommandsMenu';
import { Tooltip } from '../AndroidPolicies/components/InfoTooltip';

export interface IAppleDevice {
  id: number;
  _id: string;
  type: string;
  name: string;
  osVersion: string;
  enabled: boolean;
  serialNumber: string;
  user: string;
  deviceGroupId: string;
  deviceGroupName: string;
}

interface IActionButtonForDevice {
  id: string;
  anchorEl: HTMLElement | null;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const makeApprovalComponent = (
  row: any,
  selectApprovalGroup: (e: SelectChangeEvent, deviceName: string) => void,
  deviceGroups: IAppleDeviceGroup[],
) => (
  <Tooltip placement='top' title={ANDROID_DEVICE_MSG.DEVICE_APPROVAL_ACTION_DESCRIPTION}>
    <Button
      variant='contained'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
      }}
    >
      <Select
        fullWidth
        labelId='group-select-label2'
        id={String(row.id)}
        label='Group'
        value=''
        onChange={(e) => selectApprovalGroup(e, row._id)}
        displayEmpty
        variant='outlined'
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Typography variant='button' color={COLOR_WHITE}>
                Approve
              </Typography>
            );
          }
          return <Typography variant='button'>Options</Typography>;
        }}
        sx={{
          '& .MuiSelect-select': {
            padding: '6px 16px',
            paddingRight: '32px !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiSelect-icon': {
            color: COLOR_WHITE,
          },
        }}
      >
        {deviceGroups.map((group) => (
          <MenuItem key={group._id} value={group._id}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </Button>
  </Tooltip>
);

function AppleDevices() {
  const dispatch = useDispatch<AppDispatch>();

  const appleEnrollment = useSelector((state: RootState) => state.appleEnrollment);
  const appleDevice = useSelector((state: RootState) => state.appleDevice);
  const appleDeviceGroups = useSelector(
    (state: RootState) => state.appleDeviceGroup.appleDeviceGroups,
  );
  const { page, limit, total, loading, appleDevices } = appleDevice;
  const [openEnrolmentDialog, setOpenEnrolmentDialog] = useState<boolean>(false);
  const [openMenuDropdown, setOpenMenuDropdown] = useState<IActionButtonForDevice[]>(
    appleDevice.appleDevices.map((item) => ({ id: item._id, anchorEl: null })),
  );

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage + 1));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    dispatch(setLimit(newPageSize));
  };

  useEffect(() => {
    if (
      appleEnrollment.appleEnrollment.appleId === EMPTY_STRING ||
      appleEnrollment.appleEnrollment.apnsTopic === EMPTY_STRING
    ) {
      dispatch(getAppleEnrollmentDetails());
    }
    dispatch(getAppleDeviceGroups());
    dispatch(getAppleDevices());
  }, []);

  useEffect(() => {
    // Trigger data fetch if the number of loaded devices doesn't match the total count
    // and there are already some devices loaded (to avoid initial empty state).
    if (appleDevices.length !== total && appleDevices.length !== 0) {
      dispatch(getAppleDevices());
    }
  }, [page, limit]); // Runs when page or limit changes (pagination)

  const selectGroup = (e: SelectChangeEvent, deviceName: string) => {
    const selectedGroup = e.target.value.split(' ');

    const payload: IDeviceGroupUpdatePayload = {
      deviceGroupId: selectedGroup[0],
      deviceGroupName: selectedGroup[1],
      deviceName,
      deviceType: APPLE_OS,
    };

    dispatch(updateDeviceGroup(payload));
  };

  const selectApprovalGroup = (e: SelectChangeEvent, deviceName: string) => {
    const selectedGroup = e.target.value.split(' ');

    const payload: IDeviceGroupUpdatePayload = {
      deviceGroupId: selectedGroup[0],
      deviceGroupName: selectedGroup[1],
      deviceName,
      deviceType: APPLE_OS,
      deviceApprovalStatus: DeviceApprovalStagesEnum.approved,
    };

    dispatch(updateDeviceGroup(payload));
  };

  const handleEnrolmentDialog = () => {
    setOpenEnrolmentDialog((prev) => !prev);
  };

  const appleDeviceGroupList: ISelectItem[] = [];
  appleDeviceGroups.map((group) =>
    appleDeviceGroupList.push({
      _id: group._id,
      value1: group.name,
      value2: group.description,
    }),
  );

  const handleIssueCommandMenu = (event: React.MouseEvent<HTMLElement>, deviceId: string) => {
    setOpenMenuDropdown(() => {
      const anchors: IActionButtonForDevice[] = appleDevice.appleDevices.map((item) => ({
        id: item._id,
        anchorEl: null,
      }));
      const index = anchors.findIndex((item) => item.id === deviceId);
      if (index !== -1) {
        anchors[index] = { ...anchors[index], anchorEl: event.currentTarget };
      }
      return anchors;
    });
  };

  const handleMenuDropdownClose = () => {
    setOpenMenuDropdown(() => {
      const anchors: IActionButtonForDevice[] = appleDevice.appleDevices.map((item) => ({
        id: item._id,
        anchorEl: null,
      }));
      return anchors;
    });
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'type',
      headerName: 'Type',
      width: 110,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 110,
      renderCell: (params) => (
        <span>
          {params.row.enabled === 1
            ? APPLE_DEVICE_CONST.DEVICE_ACTIVE
            : APPLE_DEVICE_CONST.DEVICE_INACTIVE}
        </span>
      ),
    },
    {
      field: 'osVersion',
      headerName: 'OS',
      width: 110,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 110,
    },
    {
      field: 'serialNumber',
      headerName: 'Serial',
      width: 140,
    },
    {
      field: 'user',
      headerName: 'User',
      width: 180,
    },
    {
      field: 'deviceGroupName',
      headerName: 'Device Group',
      width: 200,
      renderCell: (params) => {
        const status = params.row.deviceApprovalStatus;
        const disabled =
          status === DeviceApprovalStagesEnum.approval_pending ||
          status === DeviceApprovalStagesEnum.mfa_requested ||
          status === DeviceApprovalStagesEnum.mfa_denied ||
          false;
        const rowId = String(params.row.id);
        return (
          <>
            <span />
            {appleDeviceGroups.length > 0 ? (
              <Tooltip
                placement='top'
                title={disabled ? ANDROID_DEVICE_MSG.APPROVAL_PENDING_GROUP_DISABLED : ''}
              >
                <FormControl sx={{ width: '100%' }} disabled={disabled}>
                  <InputLabel id='demo-simple-select-disabled-label'>{rowId}</InputLabel>
                  <Select
                    fullWidth
                    labelId='group-select-label'
                    id={rowId}
                    label='Group'
                    value={`${params.row.deviceGroupId} ${params.row.deviceGroupName}`}
                    onChange={(e) => selectGroup(e, params.row._id)}
                  >
                    {appleDeviceGroups.map((group) => (
                      <MenuItem key={group._id} value={`${group._id} ${group.name}`}>
                        <Typography variant='body1' sx={{ fontSize: '14px' }}>
                          {group.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'deviceRestriction',
      headerName: 'Device Approval',
      width: 150,
      renderCell: (params) => {
        const ApprovedComponent = (
          <Typography variant='body1' sx={{ color: COLOR_BLACK }}>
            {DeviceApprovalStagesEnum.approved}
          </Typography>
        );
        const MFARequestedComponent = (
          <Typography variant='body1' sx={{ color: COLOR_BLACK }}>
            {DeviceApprovalStagesEnum.mfa_requested}
          </Typography>
        );
        const MFADeniedComponent = (
          <Typography variant='body1' sx={{ color: COLOR_BLACK }}>
            {DeviceApprovalStagesEnum.mfa_denied}
          </Typography>
        );
        const ApprovalPendingComponent = makeApprovalComponent(
          params.row,
          selectApprovalGroup,
          appleDeviceGroups,
        );
        const approvalActions: Record<string, JSX.Element> = {
          APPROVAL_PENDING: ApprovalPendingComponent,
          MFA_REQUESTED: MFARequestedComponent,
          MFA_DENIED: MFADeniedComponent,
          APPROVED: ApprovedComponent,
          NO_APPROVAL: ApprovedComponent, // Displaying APPROVED for the devices that are initially set not to go through approval process
        };
        return params.row.deviceApprovalStatus
          ? approvalActions[params.row.deviceApprovalStatus]
          : null;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              handleIssueCommandMenu(event, params.row._id)
            }
          >
            <MoreVertIcon />
          </IconButton>
          {openMenuDropdown?.find((item) => item.id === params.row._id)?.anchorEl ? (
            <CommandsMenu
              deviceId={params.row._id}
              anchorEl={
                openMenuDropdown?.find((item) => item.id === params.row._id)?.anchorEl || null
              }
              handleMenu={handleMenuDropdownClose}
              deviceApprovalStatus={params.row.deviceApprovalStatus}
              deviceState={params.row.enabled}
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <MainLayout>
      <CustomHeader logo={appleLogo} title={APPLE_DEVICE_CONST.PAGE_HEADER} />
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        padding={2}
        spacing={2}
      >
        <Grid item>
          <Button
            variant='contained'
            onClick={() => dispatch(getAppleDevices())}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            {appleDevice.loading ? <CircularProgress color='inherit' size={20} /> : <SyncIcon />}
            Sync Devices
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={handleEnrolmentDialog}>
            <AddIcon />
            Enroll
          </Button>
        </Grid>
      </Grid>
      <CustomTable
        rows={appleDevices}
        columns={columns}
        rowCount={total}
        paginationMode={total !== appleDevices.length ? 'server' : 'client'}
        loading={loading}
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
              page: page - 1,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        onPaginationModelChange={({ page, pageSize }: { page: number; pageSize: number }) => {
          handlePageChange(page);
          handlePageSizeChange(pageSize);
        }}
      />
      <DeviceEnrollment open={openEnrolmentDialog} handleDialog={handleEnrolmentDialog} />
      {(appleDevice.message.error || appleDevice.message.success) && (
        <ErrorSuccessSnackbar
          opened={appleDevice.message.error || appleDevice.message.success}
          onClose={() => dispatch(updateMessage(messageInit))}
          errorMessage={appleDevice.message.errorMessage}
          successMessage={appleDevice.message.successMessage}
        />
      )}
    </MainLayout>
  );
}

export default AppleDevices;
