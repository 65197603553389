import { Grid, SelectChangeEvent, Chip } from '@mui/material';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import PropertyAndSelectInput from '../../PropertyAndSelectInput';
import ComplianceRule from './ComplianceRule';
import PropertyAndInput from '../../PropertyAndInput';
import { isValidEmail } from '../../../../Signup';
import { EMPTY_STRING } from '../../../../../constants';
import { messageInit } from '../../../../../utils/common-constants';
import { updateMessage } from '../../../../../redux/slices/androidPolicy/androidPolicySlice';
import { AppDispatch } from '../../../../../redux/store';
import { ANDROID_POLICY_MSG } from '../../../../../messages';
import { Tooltip } from '../../InfoTooltip';

function Security() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const [emailInput, setEmailInput] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const {
    developerSettingsScope,
    factoryResetSetting,
    modifyAccountsDisabled,
    frpAdminEmails,
    keyguardDisabled,
    keyguardDisabledFeatures,
    accountTypesWithManagementDisabled,
  } = policy.securityPermissions;

  const handleChangeSecurityPermissions = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      securityPermissions: {
        ...prev.securityPermissions,
        [name]: value,
      },
    }));
  };

  const handleAddEmail = () => {
    if (!isValidEmail(emailInput)) {
      dispatch(
        updateMessage({
          ...messageInit,
          error: true,
          errorMessage: 'Please enter valid email',
        }),
      );
      return;
    }

    if (emailInput && !frpAdminEmails.includes(emailInput)) {
      setPolicy((prev) => ({
        ...prev,
        securityPermissions: {
          ...prev.securityPermissions,
          frpAdminEmails: [...prev.securityPermissions.frpAdminEmails, emailInput],
        },
      }));
      setEmailInput(EMPTY_STRING);
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setPolicy((prev) => ({
      ...prev,
      securityPermissions: {
        ...prev.securityPermissions,
        frpAdminEmails: prev.securityPermissions.frpAdminEmails.filter(
          (email) => email !== emailToRemove,
        ),
      },
    }));
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleAddEmail();
    }
  };

  const propertyList = [
    {
      property: 'Developer Settings',
      name: 'developerSettingsScope',
      infoText: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.DEVELOPER_SETTING_SCOPE,
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny', value: 'disabled' },
      ],
      value: developerSettingsScope,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
    },
    {
      property: 'Add New Accounts',
      name: 'modifyAccountsDisabled',
      infoText: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.MODIFY_ACCOUNT_DISABLED,
      menuItems: [
        { view: 'Allow', value: true },
        { view: 'Deny', value: false },
      ],
      value: modifyAccountsDisabled,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
    },
    {
      property: 'Disable Account Types',
      name: 'accountTypesWithManagementDisabled',
      infoText: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.DISABLE_ACCOUNT_TYPE,
      menuItems: [
        {
          view: 'Google',
          value: 'google',
        },
      ],
      value: accountTypesWithManagementDisabled,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
      isMultiSelect: true,
    },
    {
      property: 'Keyguard Disable',
      name: 'keyguardDisabled',
      infoText: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.KEYGUARD,
      menuItems: [
        { view: 'Yes', value: true },
        { view: 'No', value: false },
      ],
      value: keyguardDisabled,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
    },
    {
      property: 'Add Keyguard',
      name: 'keyguardDisabledFeatures',
      infoText: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.KEYGUARD_FEATURES,
      menuItems: [
        {
          view: 'All',
          value: 'all',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.ALL,
        },
        {
          view: 'Biometrics',
          value: 'biometrics',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.BIOMETRICS,
        },
        {
          view: 'Face',
          value: 'face',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.FACE,
        },
        {
          view: 'Fingerprint',
          value: 'fingerprint',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.FINGERPRINT,
        },
        {
          view: 'Iris',
          value: 'iris',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.IRIS,
        },
        {
          view: 'Camera',
          value: 'camera',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.CAMERA,
        },
        {
          view: 'Notifications',
          value: 'notifications',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.NOTIFICATIONS,
        },
        {
          view: 'Unredacted Notifications',
          value: 'unredacted_notifications',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.UNREDACTED_NOTIFICATIONS,
        },
        {
          view: 'Remote Input',
          value: 'remote_input',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.REMOTE_INPUT,
        },
        {
          view: 'Trust Agents',
          value: 'trust_agents',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.TRUST_AGENTS,
        },
        {
          view: 'Shortcuts',
          value: 'shortcuts',
          tooltip: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.SECURITY_TOOLTIP.SHORTCUTS,
        },
      ],
      value: keyguardDisabledFeatures,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
      isMultiSelect: true,
    },
    {
      property: 'Factory Reset',
      name: 'factoryResetSetting',
      infoText: ANDROID_POLICY_MSG.SECURITY_INFOTEXT.FACTORY_RESET,
      menuItems: [
        { view: 'Allow', value: true },
        { view: 'Deny', value: false },
      ],
      value: factoryResetSetting,
      androidVersion: '5.1+',
      profileType: `${DeviceProfileType.COMPANY_OWNED}`,
      handleChange: handleChangeSecurityPermissions,
    },
  ];

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={6}>
        {propertyList.map((item) => (
          <PropertyAndSelectInput key={`${item.property} ${item.name}`} data={item} />
        ))}
        <Tooltip title='Press Enter to Add' placement='right-end'>
          <PropertyAndInput
            property='Admin Emails'
            name='minimumVersion'
            placeholder='Emails'
            value={emailInput}
            label='Emails'
            onChange={(e) => setEmailInput(e.target.value)}
            onKeyUp={handleKeyPress}
            androidVersion='6.0+'
            profileType={DeviceProfileType.COMPANY_OWNED}
            infoText={ANDROID_POLICY_MSG.ADMIN_EMAIL_TOOLTIP}
          />
        </Tooltip>
        {frpAdminEmails.map((email) => (
          <Chip
            key={email}
            label={email}
            onDelete={() => handleRemoveEmail(email)}
            color='default'
            variant='filled'
            sx={{ maxWidth: '100%', flexShrink: 0, margin: 1 }}
          />
        ))}
      </Grid>
      <Grid item sm={6}>
        <ComplianceRule />
      </Grid>
    </Grid>
  );
}
export default Security;
