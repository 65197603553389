import androidicon from '../../../../assets/images/android-icon.png';
import { Tooltip } from '../InfoTooltip';

interface IProps {
  androidVersion: string;
}

function AndroidMinimumVersionTooltip(props: IProps) {
  const { androidVersion } = props;
  return (
    <Tooltip title={androidVersion}>
      <img src={androidicon} alt='Minimum Android Version Supported' width={15} height={15} />
    </Tooltip>
  );
}

export default AndroidMinimumVersionTooltip;
