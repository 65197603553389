import mobileicon from '../../../../assets/images/mobile-icon.png';
import { Tooltip } from '../InfoTooltip';

interface IProps {
  profileType: string;
}

function ProfileType(props: IProps) {
  const { profileType } = props;
  return (
    <Tooltip title={profileType}>
      <img src={mobileicon} alt='Minimum Android Version Supported' width={15} height={15} />
    </Tooltip>
  );
}

export default ProfileType;
