import { EMPTY_STRING } from '../../constants';
import { IAppleProfile, IRestrictions } from '../../pages/AppleProfiles/definitions';

// For backend
export function prepareProfileDataForAPI(profile: IAppleProfile) {
  const payload = {
    id: profile.id,
    name: profile.name,
    description: profile.description,
    os: profile.os,
    payloads: [
      {
        type: 'Restrictions',
        jsonPayload: {
          ...profile.restrictions?.deviceFunctionality,
          ...profile.restrictions?.security,
          ...profile.restrictions?.browser,
          ...profile.restrictions?.network,
          ...profile.restrictions?.icloud,
          ...profile.restrictions?.privacy,
          ...profile.restrictions?.datetime,
          ...profile.restrictions?.keyboard,
        },
        uuid: profile.restrictions?.uuid ?? EMPTY_STRING,
        identifier: profile.restrictions?.identifier ?? EMPTY_STRING,
      },
      {
        type: 'AppLayerVPN',
        jsonPayload: {
          ...profile.applayerVPN?.jsonPayload,
        },
        uuid: profile.applayerVPN?.uuid ?? EMPTY_STRING,
        identifier: profile.applayerVPN?.identifier ?? EMPTY_STRING,
      },
      {
        type: 'Wifi',
        arrayPayload: profile.wifi,
      },
    ],
  };
  return payload;
}

// For frontend
/* eslint-disable  @typescript-eslint/no-explicit-any */
export function prepareProfileDataForFrontend(payload: any) {
  const parsedPayloads = JSON.parse(payload.payloads);
  const restrictionsData = JSON.parse(payload.payloads).find(
    (payload: any) => payload.type === 'Restrictions',
  );

  const uuid = restrictionsData.uuid ?? EMPTY_STRING;
  const identifier = restrictionsData.identifier ?? EMPTY_STRING;
  const restrictions = restrictionsData.jsonPayload;

  const applayerVPNData = JSON.parse(payload.payloads).find(
    (payload: any) => payload.type === 'AppLayerVPN',
  );

  const wifiData = parsedPayloads
    .filter((item: any) => item.type === 'Wifi')
    .flatMap((wifi: any) => wifi.arrayPayload || []);

  const profile: IAppleProfile = {
    id: payload.id,
    name: payload.name,
    description: payload.description,
    os: payload.os,
    restrictions: makeRestrictionsTab(restrictions, uuid, identifier),
    applayerVPN: applayerVPNData,
    wifi: makeWifiTab(wifiData),
  };
  return profile;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function makeRestrictionsTab(restrictions: any, uuid: string, identifier: string) {
  const result: IRestrictions = {
    uuid,
    identifier,
    deviceFunctionality: makeDeviceFunctionalityTab(restrictions),
    security: makeSecurityTab(restrictions),
    advancedSecurity: makeAdvancedSecurityTab(restrictions),
    browser: makeBrowserTab(restrictions),
    applications: makeApplicationsTab(restrictions),
    network: makeNetworkTab(restrictions),
    icloud: makeIcloudTab(restrictions),
    privacy: makePrivacyTab(restrictions),
    datetime: makeDateTimeTab(restrictions),
    keyboard: makeKeyardSettingsTab(restrictions),
  };

  return result;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function makeDeviceFunctionalityTab(restrictions: any) {
  return {
    allow_use_of_camera: restrictions.allow_use_of_camera ?? true,
    allow_face_time: restrictions.allow_face_time ?? true,
    allow_screen_capture: restrictions.allow_screen_capture ?? true,
    allow_airdrop: restrictions.allow_airdrop ?? true,
    force_airdrop_unmanaged: restrictions.force_airdrop_unmanaged ?? false,
    allow_imessage: restrictions.allow_imessage ?? true,
    allow_spotlight_internet: restrictions.allow_spotlight_internet ?? true,
    allow_siri: restrictions.allow_siri ?? true,
    allow_siri_when_locked: restrictions.allow_siri_when_locked ?? true,
    allow_siri_to_query_web: restrictions.allow_siri_to_query_web ?? true,
    allow_modify_device_name: restrictions.allow_modify_device_name ?? true,
    allow_airprint: restrictions.allow_airprint ?? true,
    allow_cred_store_airprint: restrictions.allow_cred_store_airprint ?? true,
    allow_beacon_discovery_airprint: restrictions.allow_beacon_discovery_airprint ?? true,
    enforce_tls_trusted_cert_airprint: restrictions.enforce_tls_trusted_cert_airprint ?? false,
  };
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function makeSecurityTab(restrictions: any) {
  return {
    allow_open_from_managed_to_unmanaged: restrictions.allow_open_from_managed_to_unmanaged ?? true,
    allow_open_from_unmanaged_to_managed: restrictions.allow_open_from_unmanaged_to_managed ?? true,
    require_managed_pasteboard: restrictions.require_managed_pasteboard ?? true,
    force_encrypted_backup: restrictions.force_encrypted_backup ?? false,
    allow_erase_content_settings: restrictions.allow_erase_content_settings ?? true,
    allow_configure_screentime_restriction:
      restrictions.allow_configure_screentime_restriction ?? true,
    allow_passbook_when_locked: restrictions.allow_passbook_when_locked ?? true,
    allow_biometric_unlock: restrictions.allow_biometric_unlock ?? true,
    allow_biometric_modification: restrictions.allow_biometric_modification ?? true,
  };
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function makeBrowserTab(restrictions: any) {
  return {
    allow_safari_browser: restrictions.allow_safari_browser ?? true,
    allow_safari_autofill: restrictions.allow_safari_autofill ?? true,
    allow_safari_javascript: restrictions.allow_safari_javascript ?? true,
    allow_safari_popups: restrictions.allow_safari_popups ?? true,
    allow_safari_accept_cookies: restrictions.allow_safari_accept_cookies ?? '2',
    allow_safari_fraud_website_warning: restrictions.allow_safari_fraud_website_warning ?? true,
  };
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function makeApplicationsTab(restrictions: any) {
  return {
    allow_appstore: restrictions.allow_appstore ?? true,
    allow_untrusted_app_installation: restrictions.allow_untrusted_app_installation ?? true,
    allow_configurator_itunes_to_update_apps:
      restrictions.allow_configurator_itunes_to_update_apps ?? true,
    allow_app_deletion: restrictions.allow_app_deletion ?? true,
    allow_system_app_deletion: restrictions.allow_system_app_deletion ?? true,
    allow_auto_download_apps_on_multiple_devices_with_same_appleid:
      restrictions.allow_auto_download_apps_on_multiple_devices_with_same_appleid ?? true,

    allow_inapp_purchase: restrictions.allow_inapp_purchase ?? true,
    allow_gamecenter: restrictions.allow_gamecenter ?? true,
    allow_gamecenter_multiplayer: restrictions.allow_gamecenter_multiplayer ?? true,
    allow_gamecenter_add_friends: restrictions.allow_gamecenter_add_friends ?? true,

    allow_modify_notification_setting: restrictions.allow_modify_notification_setting ?? true,

    allow_itunes: restrictions.allow_itunes ?? true,
    allow_itunes_file_sharing: restrictions.allow_itunes_file_sharing ?? true,

    allow_news_app: restrictions.allow_news_app ?? true,
    allow_podcast_app: restrictions.allow_podcast_app ?? true,

    allow_music_service: restrictions.allow_music_service ?? true,
    allow_radio_service: restrictions.allow_radio_service ?? true,

    allow_ibookstore: restrictions.allow_ibookstore ?? true,
    allow_ibookstore_erotica: restrictions.allow_ibookstore_erotica ?? true,
  };
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function makeNetworkTab(restrictions: any) {
  return {
    allow_sync_while_roaming: restrictions.allow_sync_while_roaming ?? true,
    allow_modify_app_cellular_data: restrictions.allow_modify_app_cellular_data ?? true,
    allow_modify_bluetooth_settings: restrictions.allow_modify_bluetooth_settings ?? true,
    allow_modify_bluetooth_sharing_settings_in_settings_app:
      restrictions.allow_modify_bluetooth_sharing_settings_in_settings_app ?? true,

    allow_create_vpn_config: restrictions.allow_create_vpn_config ?? true,
    force_wifi_on: restrictions.force_wifi_on ?? false,
    allow_wifi_connect_to_allowed_networks:
      restrictions.allow_wifi_connect_to_allowed_networks ?? false,
    allow_modify_hotspot_settings: restrictions.allow_modify_hotspot_settings ?? true,
    allow_modify_esim_settings: restrictions.allow_modify_esim_settings ?? true,
    allow_nfc_comm: restrictions.allow_nfc_comm ?? true,
  };
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function makeIcloudTab(restrictions: any) {
  return {
    allow_device_backup_icloud: restrictions.allow_device_backup_icloud ?? true,
    allow_managed_app_icloud_sync: restrictions.allow_managed_app_icloud_sync ?? true,
    allow_icloud_document_sync: restrictions.allow_icloud_document_sync ?? true,
    allow_shared_stream_icloud_sync: restrictions.allow_shared_stream_icloud_sync ?? true,
    allow_keychain_icloud_sync: restrictions.allow_keychain_icloud_sync ?? true,
    allow_icloud_photo_library: restrictions.allow_icloud_photo_library ?? true,
    allow_ent_book_backup_icloud: restrictions.allow_ent_book_backup_icloud ?? true,
    allow_ent_book_metadata_icloud_sync: restrictions.allow_ent_book_metadata_icloud_sync ?? true,
    allow_icloud_private_relay: restrictions.allow_icloud_private_relay ?? true,
  };
}

function makePrivacyTab(restrictions: any) {
  return {
    allow_find_my_friends: restrictions.allow_find_my_friends ?? true,
    allow_modify_find_my_friends_settings:
      restrictions.allow_modify_find_my_friends_settings ?? true,
    allow_find_my_device: restrictions.allow_find_my_device ?? true,
    allow_send_diagnostics_apple: restrictions.allow_send_diagnostics_apple ?? true,
    allow_modify_diagnostics_apple: restrictions.allow_modify_diagnostics_apple ?? true,
    force_limit_ad_tracking: restrictions.force_limit_ad_tracking ?? false,

    allow_lockscreen_control_center: restrictions.allow_lockscreen_control_center ?? true,
    allow_lockscreen_notification_center: restrictions.allow_lockscreen_notification_center ?? true,
    allow_lockscreen_today_view: restrictions.allow_lockscreen_today_view ?? true,
    allow_mail_privacy_protection: restrictions.allow_mail_privacy_protection ?? true,
  };
}
function makeAdvancedSecurityTab(restrictions: any) {
  return {
    allow_conf_profile_installation: restrictions.allow_conf_profile_installation ?? true,
    allow_account_modification: restrictions.allow_account_modification ?? true,
    allow_untrusted_tls_prompt: restrictions.allow_untrusted_tls_prompt ?? true,
    allow_usb_restriction_mode: restrictions.allow_usb_restriction_mode ?? true,
    allow_usb_file_drive_sharing: restrictions.allow_usb_file_drive_sharing ?? true,
    allow_unpaired_external_boot_to_recovery:
      restrictions.allow_unpaired_external_boot_to_recovery ?? false,

    allow_password_sharing: restrictions.allow_password_sharing ?? true,
    allow_password_proximity_requests:
      restrictions.allow_password_sharing_proximity_requests ?? true,
    allow_passcode_modify: restrictions.allow_passcode_modify ?? true,

    force_airplay_incoming_requests_pp: restrictions.force_airplay_incoming_requests_pp ?? false,
    force_airplay_outgoing_requests_pp: restrictions.force_airplay_outgoing_requests_pp ?? false,

    allow_apple_watch_pairing: restrictions.allow_apple_watch_pairing ?? true,
    force_watch_wrist_detect: restrictions.force_watch_wrist_detect ?? false,

    allow_new_device_setup_in_proximity: restrictions.allow_new_device_setup_in_proximity ?? true,
    allow_autofill_password: restrictions.allow_autofill_password ?? true,
    force_authenticate_for_autofill: restrictions.force_authenticate_for_autofill ?? false,
  };
}

function makeDateTimeTab(restrictions: any) {
  return {
    set_device_time: restrictions.set_device_time ?? false,
  };
}

function makeKeyardSettingsTab(restrictions: any) {
  return {
    allow_dictionary_word_lookup: restrictions.allow_dictionary_word_lookup ?? true,
    allow_predictive_keyboard: restrictions.allow_predictive_keyboard ?? true,
    allow_auto_correct: restrictions.allow_auto_correct ?? true,
    allow_spellcheck: restrictions.allow_spellcheck ?? true,
    allow_keyboard_shortcuts: restrictions.allow_keyboard_shortcuts ?? true,

    allow_dictation: restrictions.allow_dictation ?? true,
    process_dictation_on_device: restrictions.process_dictation_on_device ?? false,
    process_translation_on_device: restrictions.process_translation_on_device ?? false,

    allow_keyboard_swipe: restrictions.allow_keyboard_swipe ?? true,
  };
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
function makeWifiTab(wifiArray: any[]) {
  return wifiArray.map((wifi: any) => ({
    ssid: wifi.ssid || EMPTY_STRING,
    autoJoin: wifi.autoJoin ?? true,
    encryptionType: wifi.encryptionType || 'Any',
    hiddenNetwork: wifi.hiddenNetwork ?? true,
    MACrandomization: wifi.MACrandomization ?? true,
    password: wifi.password || EMPTY_STRING,
    proxyType: wifi.proxyType || 'None',
    proxyServer: wifi.proxyServer || EMPTY_STRING,
    proxyServerPort: wifi.proxyServerPort || 0,
    proxyUsername: wifi.proxyUsername || EMPTY_STRING,
    proxyPassword: wifi.proxyPassword || EMPTY_STRING,
    proxyPACURL: wifi.proxyPACURL || EMPTY_STRING,
    uuid: wifi.uuid || EMPTY_STRING,
    identifier: wifi.identifier || EMPTY_STRING,
  }));
}
