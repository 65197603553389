import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { RESET_DEVICE_PASSWORD_TOOLTIP, RESET_PASSWORD_WARNING } from '../constants';
import { IIssueCommandEnum, IIssueCommandPayload, IResetPasswordFlagEnum } from '../definitions';
import { ANDROID_DEVICE_CONST } from '../../../constants';
import { Tooltip } from '../../AndroidPolicies/components/InfoTooltip';

interface IResetPasswordFields {
  newPassword: string;
  require_entry: boolean;
  no_credentials_on_boot: boolean;
  lock_now: boolean;
}

interface IProps {
  deviceName: string;
  deviceManagementMode: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sendCommand: (payload: IIssueCommandPayload) => void;
}

function ResetDevicePasswordDialog(props: IProps) {
  const { deviceName, deviceManagementMode, open, setOpen, sendCommand } = props;

  const resetPasswordFieldsInit: IResetPasswordFields = {
    newPassword: '',
    lock_now: false,
    no_credentials_on_boot: false,
    require_entry: false,
  };
  const [resetPassFields, setResetPassFields] = useState<IResetPasswordFields>(
    resetPasswordFieldsInit,
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    setResetPassFields((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = () => {
    // eslint-disable-next-line prefer-const
    let resetPasswordActions: IResetPasswordFlagEnum[] = [];
    if (resetPassFields.lock_now) {
      resetPasswordActions.push(IResetPasswordFlagEnum.LOCK_NOW);
    }
    if (resetPassFields.no_credentials_on_boot) {
      resetPasswordActions.push(IResetPasswordFlagEnum.DO_NOT_ASK_CREDENTIALS_ON_BOOT);
    }
    if (resetPassFields.require_entry) {
      resetPasswordActions.push(IResetPasswordFlagEnum.REQUIRE_ENTRY);
    }
    const payload: IIssueCommandPayload = {
      deviceName,
      commandDetails: {
        device_command: IIssueCommandEnum.RESET_PASSWORD,
        newPassword: resetPassFields.newPassword,
        resetPasswordActions,
      },
    };
    sendCommand(payload);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      sx={{ height: '60vh' }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle>Reset Device Password</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />{' '}
      </IconButton>
      <Divider />

      <DialogContent>
        <Alert severity='warning' sx={{ marginBottom: 2 }}>
          <AlertTitle>{RESET_PASSWORD_WARNING}</AlertTitle>
        </Alert>
        <Grid container>
          <Grid item sm={4} sx={{ paddingBottom: 2 }}>
            New Password
          </Grid>
          <Grid item sm={7}>
            <TextField
              required
              fullWidth
              type='text'
              size='small'
              id='newPassword'
              name='newPassword'
              placeholder='Enter new device password'
              variant='outlined'
              inputProps={{
                style: {
                  padding: '4px',
                },
              }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={4}>
            Additional settings
          </Grid>
          <Grid item sm={7}>
            <Grid container direction='column'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={resetPassFields.lock_now}
                    onChange={handleChange}
                    name={IResetPasswordFlagEnum.LOCK_NOW}
                  />
                }
                label='Lock Device'
              />
              <Tooltip
                title={
                  deviceManagementMode === ANDROID_DEVICE_CONST.BYOD
                    ? RESET_DEVICE_PASSWORD_TOOLTIP
                    : ''
                }
                placement='top'
                arrow
              >
                <FormControlLabel
                  control={
                    <span>
                      <Checkbox
                        checked={resetPassFields.no_credentials_on_boot}
                        onChange={handleChange}
                        name={IResetPasswordFlagEnum.DO_NOT_ASK_CREDENTIALS_ON_BOOT}
                        disabled={deviceManagementMode === ANDROID_DEVICE_CONST.BYOD}
                      />
                    </span>
                  }
                  label='Do not ask password on boot'
                />
              </Tooltip>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={resetPassFields.require_entry}
                    onChange={handleChange}
                    name={IResetPasswordFlagEnum.REQUIRE_ENTRY}
                  />
                }
                label='Do not allow other admins to change the password again until the user has entered it.'
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmit}>
          Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetDevicePasswordDialog;
