import { CircularProgress, Grid, SelectChangeEvent } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { PolicyContext } from '../../../..';
import PropertyAndSelectInput from '../../../PropertyAndSelectInput';
import DeviceProfileType from '../../../../constants';
import { ANDROID_POLICY_MSG } from '../../../../../../messages';

interface IVpnApp {
  view: ReactNode;
  value: string;
}

function VPN() {
  const { policy, setPolicy } = useContext(PolicyContext);

  const { vpn } = policy.network;

  const [apps, setApps] = useState<IVpnApp[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    if (name === 'vpnConfigDisabled') {
      setPolicy((prev) => ({
        ...prev,
        network: {
          ...prev.network,
          vpn: {
            ...prev.network?.vpn,
            vpnConfigDisabled: value === 'true',
          },
        },
      }));
    } else {
      setPolicy((prev) => ({
        ...prev,
        network: {
          ...prev.network,
          vpn: {
            ...prev.network?.vpn,
            alwaysOnVpnPackage: {
              ...prev.network?.vpn.alwaysOnVpnPackage,
              [name]: value,
            },
          },
        },
      }));
    }
  };

  const fetchAppsList = async () => {
    const menuItems: IVpnApp[] = [];
    try {
      if (
        policy.appCatalog.applications.length > 0 &&
        policy.appCatalog.applications[0].appMeta.iconUrl === ''
      ) {
        const data: IVpnApp[] = [
          {
            view: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={25} /> <span style={{ marginLeft: '8px' }}>Loading...</span>
              </span>
            ),
            value: '',
          },
        ];
        setApps(data);
      } else {
        policy.appCatalog.applications.forEach((app) => {
          menuItems.push({
            view: (
              <span key={app.appname} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={app.appMeta.iconUrl} height={25} width={25} alt={app.appMeta.title} />
                <span style={{ marginLeft: '8px' }}>{app.appMeta.title}</span>
              </span>
            ),
            value: app.appname,
          });
        });
        setApps(menuItems);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAppsList();
  }, [JSON.stringify(policy.appCatalog.applications)]);

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={8}>
        <PropertyAndSelectInput
          data={{
            property: 'VPN Configuration',
            name: 'vpnConfigDisabled',
            infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.VPN_CONFIG,
            menuItems: [
              { view: 'Allow', value: 'true' },
              { view: 'Deny', value: 'false' },
            ],
            value: vpn.vpnConfigDisabled,
            androidVersion: '7.0+',
            profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
            handleChange,
          }}
        />
        <PropertyAndSelectInput
          data={{
            property: 'Select VPN App',
            name: 'packageName',
            infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.SELECT_VPN_APP,
            menuItems: apps,
            value:
              apps.length === 0 || apps[0].value === '' ? '' : vpn.alwaysOnVpnPackage.packageName,
            androidVersion: '7.0+',
            profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
            handleChange,
          }}
        />
        <PropertyAndSelectInput
          data={{
            property: 'Allow networking without VPN',
            name: 'lockdownEnabled',
            infoText: ANDROID_POLICY_MSG.NETWORK_INFOTEXT.ALLOW_NETWORK_WITHOUT_VPN,
            menuItems: [
              { view: 'Allow', value: true },
              { view: 'Deny', value: false },
            ],
            value: vpn.alwaysOnVpnPackage.lockdownEnabled,
            androidVersion: '7.0+',
            profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
            handleChange,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default VPN;
