import { Grid, TextField, Typography } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../..';
import AndroidMinimumVersion from '../AndroidMinimumVersion';
import ProfileType from '../ProfileType';
import { COLOR_RED } from '../../../../theme';
import InfoTooltip from '../InfoTooltip';

interface IProps {
  property: string;
  name: string;
  value: number | string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line react/require-default-props
  androidVersion?: string;
  // eslint-disable-next-line react/require-default-props
  profileType?: string;
  // eslint-disable-next-line react/require-default-props
  infoText?: string;
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

PropertyAndInput.defaultProps = {
  isRequired: false,
};

function PropertyAndInput(props: IProps) {
  const {
    property,
    name,
    value,
    onChange,
    androidVersion,
    profileType,
    infoText,
    isRequired,
    placeholder,
    ...restProps
  } = props;
  const { policy } = useContext(PolicyContext);

  return (
    <Grid container direction='row' alignItems='center' paddingBottom={2}>
      <Grid item sm={6}>
        <Typography variant='body1'>
          {property} {isRequired && <span style={{ color: COLOR_RED }}>*</span>}
        </Typography>
        <div className='icon-container'>
          {androidVersion && <AndroidMinimumVersion androidVersion={androidVersion} />}
          {profileType && <ProfileType profileType={profileType} />}
          {infoText && <InfoTooltip infoText={infoText} />}
        </div>
      </Grid>
      <Grid item sm={6}>
        <TextField
          fullWidth
          size='small'
          name={name}
          value={value}
          onChange={onChange}
          inputProps={{ min: 0 }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={policy.policyName === process.env.REACT_APP_DEFAULT_ANDROID_POLICY_NAME}
          placeholder={placeholder}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restProps}
        />
      </Grid>
    </Grid>
  );
}

export default PropertyAndInput;
