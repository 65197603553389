import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import PropertyAndSelectInput from '../../PropertyAndSelectInput';
import { times } from '../CustomRestrictions/TimeRestriction/definitions';
import FreezePeriod from './FreezePeriod';
import { ANDROID_POLICY_CONST } from '../../../../../constants';
import { ANDROID_POLICY_MSG } from '../../../../../messages';
import InfoTooltip from '../../InfoTooltip';

function OSPatchManagement() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { systemUpdate } = policy;

  const handleSystemUpdateSetting = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => {
      const newSecurityPermissions = {
        ...prev.systemUpdate,
        [name]: value,
      };
      return {
        ...prev,
        systemUpdate: newSecurityPermissions,
      };
    });
  };
  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={6}>
        <PropertyAndSelectInput
          key='System Updates'
          data={{
            property: 'System Updates',
            name: 'type',
            menuItems: [
              {
                view: 'Automatic',
                value: ANDROID_POLICY_CONST.OS_UPDATE.AUTOMATIC,
                tooltip: 'Install automatically as soon as an update is available.',
              },
              {
                view: 'Windowed',
                value: ANDROID_POLICY_CONST.OS_UPDATE.WINDOWED,
                tooltip: 'Install automatically within a daily maintenance window.',
              },
              {
                view: 'Postponed',
                value: ANDROID_POLICY_CONST.OS_UPDATE.POSTPONE,
                tooltip:
                  'Postpone automatic install up to a maximum of 30 days. This policy does not affect security updates (e.g. monthly security patches).',
              },
            ],
            value: systemUpdate?.type,
            infoText: ANDROID_POLICY_MSG.OS_UPDATE_INFOTEXT.SYSTEM_UPDATES,
            androidVersion: '5.1+',
            profileType: `${DeviceProfileType.COMPANY_OWNED}`,
            handleChange: handleSystemUpdateSetting,
          }}
        />
        {systemUpdate?.type === ANDROID_POLICY_CONST.OS_UPDATE.WINDOWED ? (
          <Grid container display='flex' justifyContent='space-between' alignItems='center'>
            <Grid item sm={6} display='flex' gap={1} alignItems='center'>
              <Typography variant='body1' color='text.secondary'>
                Maintainence Window
              </Typography>
              <InfoTooltip infoText={ANDROID_POLICY_MSG.OS_UPDATE_INFOTEXT.MAINTAINENCE_WINDOW} />
            </Grid>
            <Grid item sm={3}>
              <FormControl fullWidth size='small'>
                <InputLabel id='startMinutes-select-label'>Start</InputLabel>
                <Select
                  labelId='startMinutes-select-label'
                  id='startMinutes-select'
                  label='Start'
                  size='small'
                  name='startMinutes'
                  value={systemUpdate?.startMinutes}
                  onChange={handleSystemUpdateSetting}
                >
                  {times.map((time) => (
                    <MenuItem key={time} value={time}>
                      <Typography variant='body1'>{time}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3}>
              <FormControl fullWidth size='small'>
                <InputLabel id='endMinutes-select-label'>End</InputLabel>
                <Select
                  labelId='endMinutes-select-label'
                  id='endMinutes-select'
                  label='End'
                  size='small'
                  name='endMinutes'
                  value={systemUpdate?.endMinutes}
                  onChange={handleSystemUpdateSetting}
                >
                  {times.map((time) => (
                    <MenuItem key={time} value={time}>
                      <Typography variant='body1'>{time}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}
        <FreezePeriod />
      </Grid>
    </Grid>
  );
}

export default OSPatchManagement;
