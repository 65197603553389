export interface IDelegatedScopeObject {
  view: string;
  value: string;
  selected: boolean;
  infoText: string;
}
const initDelegatedScopes: IDelegatedScopeObject[] = [
  {
    view: 'Certificate Installation & Management',
    value: 'cert_install',
    selected: false,
    infoText: 'Allows managing certificates and their installation.',
  },
  {
    view: 'Managed Configuration',
    value: 'managed_config',
    selected: false,
    infoText: 'Allows managing app settings and configurations.',
  },
  {
    view: 'Block Unistallation',
    value: 'block_uninstall',
    selected: false,
    infoText: 'Prevents the uninstallation of specific apps.',
  },
  {
    view: 'Permission State',
    value: 'grant',
    selected: false,
    infoText: 'Allows managing app permissions and grants.',
  },
  {
    view: 'Package Access State',
    value: 'package_access',
    selected: false,
    infoText: 'Controls access to app packages.',
  },
  {
    view: 'Enable System App',
    value: 'enable_system_app',
    selected: false,
    infoText: 'Allows enabling system apps on the device.',
  },
];
export default initDelegatedScopes;
