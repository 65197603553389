import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAppleInvitationPayload } from '../../../components/Enrollments/definitions';
import { HTTP_STATUS_CODE } from '../../../constants';
import { IDeviceGroupUpdatePayload } from '../../../pages/AndroidDevices/definitions';
import { IAppleDevice } from '../../../pages/AppleDevices';
import axiosInstance from '../../../utils/axiosInstance';
import { IMessage, messageInit } from '../../../utils/common-constants';
import {
  APPLE_DEVICE_ACTIVATE_DEFAULT_VPN_PROFILE,
  APPLE_DEVICE_RESTRICTION_RETRY_MFA,
  APPLE_DEVICES_LIST_ROUTE,
  APPLE_IOS_DEVICE_DEPROVISION_ROUTE,
  APPLE_IOS_DEVICE_ENROLLMENT_ROUTE,
  LINK_APPLE_DEVICE_WITH_DEVICE_GROUP_ROUTE,
} from '../../../utils/routes-defs';
import { RootState } from '../../store';

interface IAppledDeviceSliceState {
  appleDevices: IAppleDevice[];
  loading: boolean;
  message: IMessage;
  page: number;
  limit: number;
  total: number;
  isAll: boolean;
}

const initialState: IAppledDeviceSliceState = {
  appleDevices: [],
  loading: false,
  message: messageInit,
  page: 1,
  limit: 25,
  total: 0,
  isAll: false,
};

export const getAppleDevices = createAsyncThunk(
  'androidDevice/getAppleDevices',
  async (_, thunkAPI) => {
    try {
      const { getState } = thunkAPI;
      const { page, limit, isAll } = (getState() as RootState).appleDevice;

      const response = await axiosInstance().get(
        `${APPLE_DEVICES_LIST_ROUTE}?page=${page}&limit=${limit}&all=${isAll}`,
      );

      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const sendEnrollmentInvitation = createAsyncThunk(
  'appleDevice/sendEnrollmentInvitation',
  async (payload: IAppleInvitationPayload, thunkAPI) => {
    try {
      if (payload.sendEmail) {
        thunkAPI.dispatch(
          updateMessage({
            ...messageInit,
            success: true,
            successMessage: 'Enrollment email sent successfully',
          }),
        );
      }
      const response = await axiosInstance().post(APPLE_IOS_DEVICE_ENROLLMENT_ROUTE, payload);
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const activateDefaultVPNProfile = createAsyncThunk(
  'appleDevice/activateDefaultVPNProfile',
  async (payload: { deviceId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().post(
        APPLE_DEVICE_ACTIVATE_DEFAULT_VPN_PROFILE,
        payload,
      );
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.status !== HTTP_STATUS_CODE.FORBIDDEN &&
        err.response.data.errors
      ) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const updateDeviceGroup = createAsyncThunk(
  'appleDevice/updateDeviceGroup',
  async (payload: IDeviceGroupUpdatePayload, thunkAPI) => {
    try {
      const response = await axiosInstance().post(
        LINK_APPLE_DEVICE_WITH_DEVICE_GROUP_ROUTE,
        payload,
      );
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const sendMFA = createAsyncThunk(
  'appleDevice/sendMFA',
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  async (payload: any, thunkAPI) => {
    try {
      const response = await axiosInstance().post(APPLE_DEVICE_RESTRICTION_RETRY_MFA, payload);
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

export const deleteDevice = createAsyncThunk(
  'appleDevice/deleteDevice',
  async (payload: { deviceId: string }, thunkAPI) => {
    try {
      const response = await axiosInstance().delete(APPLE_IOS_DEVICE_DEPROVISION_ROUTE, {
        data: payload,
      });
      return response.data;
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.errors) {
        return thunkAPI.rejectWithValue(err.response.data.errors[0].message);
      }
      throw err;
    }
  },
);

const appledDeviceSlice = createSlice({
  name: 'appleDevice',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setAll: (state, action) => {
      state.isAll = action.payload;
    },
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
    updateTotalDevicesCount: (state, action) => {
      state.total = action.payload;
    },
    updateAppleDevicesArray: (state, action) => {
      state.appleDevices = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get android devices
    builder.addCase(getAppleDevices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppleDevices.fulfilled, (state, action) => {
      const { devices, total, message } = action.payload;
      const allAppleDevices = devices.map((device: any, idx: number) => ({
        id: (state.page - 1) * state.limit + idx + 1,
        ...device,
      }));

      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
      state.total = total;
      state.loading = false;
      state.appleDevices = allAppleDevices;
    });
    builder.addCase(getAppleDevices.rejected, (state, action) => {
      const message = action.payload;

      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message as string,
      };
    });

    // send enrollment invitation
    builder.addCase(sendEnrollmentInvitation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEnrollmentInvitation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendEnrollmentInvitation.rejected, (state, action) => {
      const message = action.payload;

      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message as string,
      };
    });

    // activate VPN profile
    builder.addCase(activateDefaultVPNProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateDefaultVPNProfile.fulfilled, (state, action) => {
      const { message } = action.payload;

      state.loading = false;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message as string,
      };
    });
    builder.addCase(activateDefaultVPNProfile.rejected, (state, action) => {
      const message = action.payload;

      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message as string,
      };
    });

    // update android device group
    builder.addCase(updateDeviceGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDeviceGroup.fulfilled, (state, action) => {
      const { message } = action.payload;
      const { deviceName, deviceGroupId, deviceGroupName } = action.meta.arg;
      const updatedAppleDevices = state.appleDevices.map((device: IAppleDevice) => {
        if (device._id === deviceName) {
          return {
            ...device,
            deviceGroupId,
            deviceGroupName,
          };
        }
        return device;
      });

      state.loading = false;
      state.appleDevices = updatedAppleDevices;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: message,
      };
    });
    builder.addCase(updateDeviceGroup.rejected, (state, action) => {
      const message = action.payload;

      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message as string,
      };
    });

    // MFA resend
    builder.addCase(sendMFA.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(sendMFA.fulfilled, (state, action) => {
      const responseMessage = action.payload.message;

      state.loading = false;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(sendMFA.rejected, (state, action) => {
      const message = action.payload;

      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message as string,
      };
    });

    // delete device entry
    builder.addCase(deleteDevice.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDevice.fulfilled, (state, action) => {
      const responseMessage = action.payload.message;

      state.loading = false;
      state.message = {
        ...messageInit,
        success: true,
        successMessage: responseMessage,
      };
    });
    builder.addCase(deleteDevice.rejected, (state, action) => {
      const message = action.payload;

      state.loading = false;
      state.message = {
        ...messageInit,
        error: true,
        errorMessage: message as string,
      };
    });
  },
});

export const {
  updateMessage,
  setPage,
  setLimit,
  setAll,
  updateTotalDevicesCount,
  updateAppleDevicesArray,
} = appledDeviceSlice.actions;
export default appledDeviceSlice;
