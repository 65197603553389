import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { PolicyContext } from '../../../../..';
import DeviceProfileType from '../../../../../constants';
import { IAndroidApp } from '../../../../../definitions';
import PropertyAndInput from '../../../../PropertyAndInput';
import PropertyAndSelectInput from '../../../../PropertyAndSelectInput';
import initDelegatedScopes, { IDelegatedScopeObject } from './definitions';
import { ANDROID_POLICY_MSG } from '../../../../../../../messages';
import InfoTooltip from '../../../../InfoTooltip';

interface IProps {
  app: IAndroidApp;
  open: boolean;
  onClose: () => void;
}
function AdvancedSettings(props: IProps) {
  const { app, open, onClose } = props;
  const { setPolicy } = useContext(PolicyContext);
  const { appUpdateMode, appConnectedWorkAndPersonal, appWidgets, appScopes } = app;
  const [allDelegatedScopes, setAllDelegatedScopes] = useState<IDelegatedScopeObject[]>(
    JSON.parse(JSON.stringify(initDelegatedScopes)),
  );

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      let allApps = prev.appCatalog.applications as any;
      allApps = allApps.map((elem: IAndroidApp) => JSON.parse(JSON.stringify(elem)));

      const selectedAppIndex = allApps.findIndex(
        (item: IAndroidApp) => item.appname === app.appname,
      );
      if (selectedAppIndex !== -1) {
        allApps[selectedAppIndex][name] = value;
      }
      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          applications: allApps,
        },
      };
    });
  };

  const handleMinimumVersion = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMinimumVersion = Number(e.target.value);
    setPolicy((prev) => {
      const newApplicationsState = prev.appCatalog?.applications?.map((obj) => {
        if (obj.appname === app.appname) {
          return { ...obj, appMiniVersion: newMinimumVersion };
        }
        return obj;
      });

      return {
        ...prev,
        appCatalog: {
          ...prev.appCatalog,
          applications: newApplicationsState,
        },
      };
    });
  };

  const propertyList = [
    {
      property: 'Update Mode',
      name: 'appUpdateMode',
      menuItems: [
        {
          view: 'Low Priority',
          value: 'default',
          tooltip: (
            <>
              <p>The app is updated when all of the following constraints are met:</p>
              <p> - The device is not actively used.</p>
              <p> - The device is connected to an unmetered network.</p>
              <p> - The device is charging.</p>
              <p> - The app to be updated is not running in the foreground.</p>
            </>
          ),
        },
        {
          view: 'Postpone',
          value: 'postpone',
          tooltip:
            'The app is not automatically updated for a maximum of 90 days after the app becomes out of date. 90 days after the app becomes out of date, the latest available version is installed automatically with low priority',
        },
        {
          view: 'High Priority',
          value: 'high_priority',
          tooltip:
            'The app is updated as soon as possible. No constraints are applied.The device is notified immediately about a new update after it becomes available.',
        },
      ],
      value: appUpdateMode,
      androidVersion: '5.0+',
      infoText: ANDROID_POLICY_MSG.APP_CATALOG_INFOTEXT.UPDATE_MODE,
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
    {
      property: 'Work and Personal app connected?',
      name: 'appConnectedWorkAndPersonal',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny', value: 'disallowed' },
      ],
      value: appConnectedWorkAndPersonal,
      androidVersion: '11.0+',
      profileType: `${DeviceProfileType.BYOD}`,
      infoText: ANDROID_POLICY_MSG.APP_CATALOG_INFOTEXT.WORK_PERSONAL_APP_CONNECT,
      handleChange,
    },
    {
      property: 'Widget on home screen',
      name: 'appWidgets',
      menuItems: [
        { view: 'Allow', value: 'allowed' },
        { view: 'Deny', value: 'disallowed' },
      ],
      value: appWidgets,
      infoText: ANDROID_POLICY_MSG.APP_CATALOG_INFOTEXT.WIDGET_ON_HOME_SCREEN,
      androidVersion: '7.0+',
      profileType: `${DeviceProfileType.BYOD}`,
      handleChange,
    },
  ];

  useEffect(() => {
    // set the scopes to true which are already present for the application
    setAllDelegatedScopes((prev) => {
      const temp = [...prev];
      appScopes?.forEach((scope) => {
        const tempIndex = temp.findIndex((item) => item.value === scope);
        if (tempIndex >= 0) {
          temp[tempIndex].selected = !temp[tempIndex].selected;
        }
      });
      return temp;
    });
  }, []);

  const handleScopeSelection = (scope: IDelegatedScopeObject) => {
    setAllDelegatedScopes((prev) => {
      const temp = [...prev];
      const scopeIndex = temp.findIndex((item) => item.value === scope.value);
      if (scopeIndex >= 0) {
        temp[scopeIndex].selected = !temp[scopeIndex].selected;
      }

      setPolicy((prev) => {
        const allApplications = [...prev.appCatalog.applications];
        const requiredApplicationIndex = allApplications.findIndex(
          (application) => application.appname === app.appname,
        );

        allApplications[requiredApplicationIndex].appScopes = temp
          .filter((item) => item.selected === true)
          .map((item) => item.value);

        return {
          ...prev,
          appCatalog: {
            ...prev.appCatalog,
            applications: allApplications,
          },
        };
      });

      return temp;
    });
  };

  return (
    <Dialog fullWidth maxWidth='md' onClose={onClose} open={open}>
      <DialogTitle>Advanced Settings</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <PropertyAndSelectInput
          key='appOnVpnLockdownExemption'
          data={{
            property: 'Networking via VPN',
            name: 'appOnVpnLockdownExemption',
            infoText: ANDROID_POLICY_MSG.APP_CATALOG_INFOTEXT.VPN_LOCKDOWN_EXEMPTION,
            menuItems: [
              { view: 'Enable networking only on VPN', value: 'enforced' },
              { view: 'Allow networking even without VPN', value: 'exemption' },
            ],
            value: app.appOnVpnLockdownExemption,
            androidVersion: '10.0+',
            profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
            handleChange,
          }}
        />
        <Grid container direction='row' alignItems='center' paddingBottom={1}>
          <Grid item sm={6}>
            <Typography variant='body1' fontWeight={600}>
              Patch Management
            </Typography>
          </Grid>
        </Grid>
        <PropertyAndInput
          property='Minimum Version'
          name='minimumVersion'
          value={app.appMiniVersion || 0}
          disabled={false}
          infoText={ANDROID_POLICY_MSG.APP_CATALOG_INFOTEXT.APP_MIN_VERSION}
          onChange={handleMinimumVersion}
        />
        {propertyList.map((item) => (
          <>
            {item.name === 'appConnectedWorkAndPersonal' ? (
              <Grid container direction='row' alignItems='center' paddingBottom={2}>
                <Grid item sm={6}>
                  <Typography variant='body1' fontWeight={600}>
                    Connection with Personal Profile
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <PropertyAndSelectInput key={`${item.property} ${item.name}`} data={item} />
          </>
        ))}
        <Grid container direction='row' alignItems='center' paddingBottom={2}>
          <Grid item sm={6}>
            <Typography variant='body1' fontWeight={600}>
              Delegate Scopes
            </Typography>
          </Grid>
        </Grid>
        {allDelegatedScopes.map((item) => (
          <Grid key={item.view} gap={2} container direction='row' alignItems='center'>
            <Grid item>
              <Switch checked={item.selected} onChange={() => handleScopeSelection(item)} />
            </Grid>
            <Grid item>
              <Typography variant='body1'>{item.view}</Typography>
            </Grid>
            <Grid item>
              <InfoTooltip infoText={item.infoText} />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default AdvancedSettings;
