import { Button, Card, Grid, SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import PropertyAndInput from '../../PropertyAndInput';
import PropertyAndSelectInput from '../../PropertyAndSelectInput';
import { ANDROID_POLICY_CONST } from '../../../../../constants';
import { ANDROID_POLICY_MSG } from '../../../../../messages';

function PasswordSettings() {
  const { policy, setPolicy } = useContext(PolicyContext);
  const { passwordSettings } = policy;

  const handleChange = (event: SelectChangeEvent, index: number) => {
    const { name, value } = event.target;

    setPolicy((prev) => {
      const newPasswordSettings = prev.passwordSettings.map((elem, id) =>
        id === index
          ? {
              ...elem,
              [name]: value,
            }
          : elem,
      );
      return {
        ...prev,
        passwordSettings: newPasswordSettings,
      };
    });
  };

  const handleAddNewPasswordPolicy = () => {
    setPolicy((prev) => {
      const newSetting = {
        minLength: 6,
        minLetters: 0,
        minLowerCase: 0,
        minimumNonLetter: 0,
        minimumNumbers: 0,
        minimumSymbols: 0,
        minimumUpperCase: 0,
        quality: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.NUMERIC,
        historyLength: 2,
        maxifailedPasswords: 4,
        expirationTimeout: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.NEVER,
        scope: ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.WORK_PROFILE,
        requireUnlock: ANDROID_POLICY_CONST.PASSWORD.REQUIRE_PASSWORD_UNLOCK.DEFAULT,
        unifiedLockSettings: ANDROID_POLICY_CONST.PASSWORD.LOCK_SETTINGS.COMMON,
      };

      return {
        ...prev,
        passwordSettings: [...passwordSettings, newSetting],
      };
    });
  };

  const removePasswordSetting = (index: number) => {
    setPolicy((prev) => {
      const newPasswordSettings = prev.passwordSettings.filter((_, id) => id !== index);

      return {
        ...prev,
        passwordSettings: newPasswordSettings,
      };
    });
  };

  return (
    <>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item sm={6}>
          {passwordSettings.map((passwordSetting, index) => (
            <Card sx={{ padding: 2, marginBottom: 2, display: 'flex', flexDirection: 'column' }}>
              <PropertyAndSelectInput
                data={{
                  property: 'Profile Scope',
                  name: 'scope',
                  infoText: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.SCOPE,
                  menuItems: [
                    {
                      view: 'Work Profile',
                      value: ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.WORK_PROFILE,
                      tooltip: ANDROID_POLICY_MSG.COMMON_SCOPES.WORK_PROFILE,
                    },
                    {
                      view: 'Device Wide',
                      value: ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.DEVICE_WIDE,
                      tooltip: ANDROID_POLICY_MSG.COMMON_SCOPES.DEVICE_WIDE,
                    },
                  ],
                  value: passwordSetting.scope,
                  androidVersion: '(Device Password)5.0+ (Work Profile Password)7.0+',
                  profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Password Quality',
                  name: 'quality',
                  infoText: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.QUALITY,
                  menuItems: [
                    {
                      view: 'No Password',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.UNSPECIFIED,
                    },
                    {
                      view: 'Complex',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.COMPLEX,
                    },
                    {
                      view: 'Numeric',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.NUMERIC,
                    },
                    {
                      view: 'Numeric Complex',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.NUMERIC_COMPLEX,
                    },
                    {
                      view: 'Alphabetic',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.ALPHABETIC,
                    },
                    {
                      view: 'Alphanumeric',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.ALPHANUMERIC,
                    },
                    {
                      view: 'Biometric Weak',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_QUALITY.WEAK,
                    },
                  ],
                  value: passwordSetting.quality,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndInput
                property='Minimum length of password'
                name='minLength'
                type='number'
                value={passwordSetting.minLength}
                disabled={passwordSetting.quality === 'unspecified'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of numeric digits'
                name='minimumNumbers'
                type='number'
                value={passwordSetting.minimumNumbers}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of letters'
                name='minLetters'
                type='number'
                value={passwordSetting.minLetters}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of lowercase letters'
                name='minLowerCase'
                type='number'
                value={passwordSetting.minLowerCase}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of uppercase letters'
                name='minimumUpperCase'
                type='number'
                value={passwordSetting.minimumUpperCase}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Minimum number of symbols'
                name='minimumSymbols'
                type='number'
                value={passwordSetting.minimumSymbols}
                disabled={passwordSetting.quality !== 'complex'}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndInput
                property='Password History'
                name='historyLength'
                type='number'
                infoText={ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.PASSWORD_HISTORY}
                value={passwordSetting.historyLength}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />

              <PropertyAndSelectInput
                data={{
                  property: 'Password Expiry',
                  name: 'expirationTimeout',
                  infoText: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.PASSWORD_EXPIRY,
                  menuItems: [
                    { view: 'Never', value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.NEVER },
                    {
                      view: '1 Year',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.ONE_YEAR,
                    },
                    {
                      view: '6 Months',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.SIX_MONTHS,
                    },
                    {
                      view: '3 Months',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.THREE_MONTHS,
                    },
                    {
                      view: '1 Month',
                      value: ANDROID_POLICY_CONST.PASSWORD.PASSWORD_EXPIRY.ONE_MONTHS,
                    },
                  ],
                  value: passwordSetting.expirationTimeout,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndInput
                property='Maximum Failed Passwords'
                name='maxifailedPasswords'
                type='number'
                infoText={ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.MAX_FAIL_PASSWORDS}
                value={passwordSetting.maxifailedPasswords}
                onChange={(e: SelectChangeEvent) => handleChange(e, index)}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Require Password Unlock',
                  name: 'requireUnlock',
                  infoText: ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.UNIFIED_LOCK_SETTINGS,
                  menuItems: [
                    {
                      view: 'Device Default',
                      value: ANDROID_POLICY_CONST.PASSWORD.REQUIRE_PASSWORD_UNLOCK.DEFAULT,
                    },
                    {
                      view: 'Everyday',
                      value: ANDROID_POLICY_CONST.PASSWORD.REQUIRE_PASSWORD_UNLOCK.EVERYDAY,
                    },
                  ],
                  value: passwordSetting.requireUnlock,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                }}
              />
              <PropertyAndSelectInput
                data={{
                  property: 'Device & Work Profile Lock Settings',
                  name: 'unifiedLockSettings',
                  menuItems: [
                    {
                      view: 'Common Lock',
                      value: ANDROID_POLICY_CONST.PASSWORD.LOCK_SETTINGS.COMMON,
                      tooltip:
                        ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.UNIFIED_LOCK_SETTINGS_TOOLTIP
                          .COMMON_LOCK,
                    },
                    {
                      view: 'Separate Lock',
                      value: ANDROID_POLICY_CONST.PASSWORD.LOCK_SETTINGS.SEPERATE,
                      tooltip:
                        ANDROID_POLICY_MSG.PASSWORD_SETTINGS_INFOTEXT.UNIFIED_LOCK_SETTINGS_TOOLTIP
                          .SEPERATE_LOCK,
                    },
                  ],
                  value:
                    passwordSetting.scope !==
                    ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.DEVICE_WIDE
                      ? passwordSetting.unifiedLockSettings
                      : '',
                  isDisabled:
                    passwordSetting.scope ===
                    ANDROID_POLICY_CONST.PASSWORD.PROFILE_SCOPE.DEVICE_WIDE,
                  handleChange: (e: SelectChangeEvent) => handleChange(e, index),
                  infoText: ANDROID_POLICY_CONST.PASSWORD.LOCK_SETTINGS.TOOLTIP_MESSAGE,
                }}
              />
              <div style={{ marginTop: 'auto', width: 'fit-content', marginLeft: 'auto' }}>
                <Button
                  onClick={() => removePasswordSetting(index)}
                  style={{ backgroundColor: 'red', color: 'white' }}
                >
                  Delete
                </Button>
              </div>
            </Card>
          ))}
        </Grid>
      </Grid>

      <Button variant='contained' onClick={handleAddNewPasswordPolicy}>
        Add Password For Different Profile Scope
      </Button>
    </>
  );
}

export default PasswordSettings;
