import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { Tooltip } from '../../pages/AndroidPolicies/components/InfoTooltip';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function RadioOptions(props: any) {
  const { fieldId, fieldLabel, options, handler } = props;
  return (
    <Grid container direction='row' alignItems='center' paddingBottom={2}>
      <Grid item sm={4}>
        <Typography variant='body1'>{fieldLabel}</Typography>
      </Grid>
      <Grid item sm={6}>
        <RadioGroup
          row
          aria-labelledby={fieldId}
          name={fieldId}
          defaultValue={options[0].name}
          onChange={handler}
        >
          {options.map((radioOption: any) => (
            <FormControlLabel
              value={radioOption.name}
              control={<Radio />}
              label={
                <Tooltip title={radioOption.description} arrow>
                  <span>{radioOption.label}</span>
                </Tooltip>
              }
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

export default React.memo(RadioOptions);
