import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Card, CardContent, IconButton, Stack, Typography, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { IAndroidPolicy } from '../../definitions';
import DeletePolicyDialog from '../DeletePolicyDialog';
import { Tooltip } from '../InfoTooltip';

interface IProps {
  policy: IAndroidPolicy;
  handlePolicyDialog: (isCreate: boolean, policy: IAndroidPolicy) => void;
}

function PolicyCard(props: IProps) {
  const { policy, handlePolicyDialog } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handlePolicyDialog(false, policy);
    handleMenuClose();
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  return (
    <>
      <Card sx={{ maxWidth: 275 }} elevation={2}>
        <CardContent>
          <Stack direction='row' justifyContent='space-between'>
            <Tooltip title={policy.policyName} placement='top'>
              <Typography variant='h6' color='text.primary' gutterBottom noWrap>
                {policy.policyName}
              </Typography>
            </Tooltip>
            <div>
              <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              </Menu>
            </div>
          </Stack>
          <Stack
            direction='column'
            justifyContent='space-between'
            sx={{ height: '30px', overflow: 'hidden' }}
          >
            <Tooltip title={policy.policyDescription} placement='top'>
              <Typography color='text.primary' sx={{ fontSize: 16 }} noWrap>
                {policy.policyDescription}
              </Typography>
            </Tooltip>
          </Stack>
        </CardContent>
      </Card>
      <DeletePolicyDialog
        policy={policy}
        openDeleteDialog={deleteDialogOpen}
        handleDeleteDialog={setDeleteDialogOpen} // Pass the state setter function
      />
    </>
  );
}

export default React.memo(PolicyCard);
