import { Grid, SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../..';
import DeviceProfileType from '../../../constants';
import PropertyAndSelectInput from '../../PropertyAndSelectInput';
import { ANDROID_POLICY_MSG } from '../../../../../messages';

function DeviceFunctionalities() {
  const { policy, setPolicy } = useContext(PolicyContext);

  const {
    screenCapturePermission,
    physicalMediaPermission,
    cameraPermission,
    microphonePermission,
  } = policy.deviceFunctionality;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      deviceFunctionality: {
        ...prev.deviceFunctionality,
        [name]: value,
      },
    }));
  };

  const propertyList = [
    {
      property: 'Screen Capture',
      name: 'screenCapturePermission',
      infoText: ANDROID_POLICY_MSG.DEVICE_FUNCTIONALITY_INFOTEXT.SCREEN_CAPTURE,
      menuItems: [
        { view: 'Allow', value: true },
        { view: 'Deny', value: false },
      ],
      value: screenCapturePermission,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
    {
      property: 'Screen Recording',
      name: 'screenCapturePermission',
      infoText: ANDROID_POLICY_MSG.DEVICE_FUNCTIONALITY_INFOTEXT.SCREEN_RECORDING,
      menuItems: [
        { view: 'Allow', value: true },
        { view: 'Deny', value: false },
      ],
      value: screenCapturePermission,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
    {
      property: 'Mount Physical Media',
      name: 'physicalMediaPermission',
      infoText: ANDROID_POLICY_MSG.DEVICE_FUNCTIONALITY_INFOTEXT.MOUNT_PHYSICAL_MEDIA,
      menuItems: [
        { view: 'Allow', value: true },
        { view: 'Deny', value: false },
      ],
      value: physicalMediaPermission,
      androidVersion: '5.1+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
    {
      property: 'Camera Permission',
      name: 'cameraPermission',
      infoText: ANDROID_POLICY_MSG.DEVICE_FUNCTIONALITY_INFOTEXT.CAMERA_PERMISSION,
      menuItems: [
        { view: 'Allow', value: 'enforced', tooltip: ANDROID_POLICY_MSG.COMMON_POLICY.GRANT },
        { view: 'Deny', value: 'disabled', tooltip: ANDROID_POLICY_MSG.COMMON_POLICY.DENY },
        {
          view: 'User Choice',
          value: 'user_choice',
          tooltip: ANDROID_POLICY_MSG.COMMON_POLICY.PROMPT,
        },
      ],
      value: cameraPermission,
      androidVersion: '(BYOD)8.0+ (Company-Owned Device)5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
    {
      property: 'Microphone',
      name: 'microphonePermission',
      infoText: ANDROID_POLICY_MSG.DEVICE_FUNCTIONALITY_INFOTEXT.MICROPHONE,
      menuItems: [
        { view: 'Allow', value: 'enforced', tooltip: ANDROID_POLICY_MSG.COMMON_POLICY.GRANT },
        { view: 'Deny', value: 'disabled', tooltip: ANDROID_POLICY_MSG.COMMON_POLICY.DENY },
        {
          view: 'User Choice',
          value: 'user_choice',
          tooltip: ANDROID_POLICY_MSG.COMMON_POLICY.PROMPT,
        },
      ],
      value: microphonePermission,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
  ];

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={6}>
        {propertyList.map((item) => (
          <PropertyAndSelectInput key={`${item.property} ${item.name}`} data={item} />
        ))}
      </Grid>
    </Grid>
  );
}

export default DeviceFunctionalities;
