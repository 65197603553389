import DeviceApprovalStagesEnum from './enums';

export const GLOBAL_MSG = {};

export const ANDROID_ENROLLMENT_MSG = {
  DIALOG_TITLE: 'Enroll Android Device',
  PAGE_DISABLED_AE: 'Please register for Android Enterprise to continue...',
  DELETE_ENTERPRISE:
    'Managed devices are factory reset. Work profile and other work-related data is deleted from devices. Devices are no longer under administrative control.',
  WARNING_ENTERPRISE:
    'If you intend to create the enterprise within the same tenant in the future, it is advisable to retain the associated policies now to avoid having to recreate them from scratch later.',
  DEVICE_RESTRICTION_DESCRIPTION:
    'If unchecked the device can be used immediately after enrollment otherwise admin need to approve the device by assigning an expected policy for this user',
  MFA_FLOW_DESCRIPTION:
    'The device will go through the configured MFA flow before being assigned with the actual policy',
  ADMIN_APPROVAL_FLOW_DESCRIPTION:
    'Admin can approve and assign the actual policy after manual approval',
  PERSONAL_PROFILE_DESCRIPTION:
    'Selecting this option enables the Work profile setup on personal devices',
  CORPORATE_PROFILE_DESCRIPTION: 'Select this option if the device is company-owned',
  ENROLLMENT_EMAIL_OPTION_DESCRIPTION:
    'Enrollment steps will be sent over email to the selected user(Recommended for personal devices)',
  ENROLLMENT_STEPS_DISPLAY_DESCRIPTION:
    'Enrollment steps will be shown here(Recommended for corporate devices)',
  ENROLLMENT_DEVICE_APPROVAL_DESCRIPTION: `As you want this device to be approved by admin before the actual use, it is recommended it is added to default group with default policy that won't show any apps or login options.`,
  MFA_SELECTED_WARNING_FOR_MINIORANGE_ENDUSER: `MFA flow currently works only for the users present in your miniOrange IAM`,
};

export const ANDROID_DEVICE_MSG = {
  DEVICE_APPROVAL_ACTION_DESCRIPTION: 'Select a group from the dropdown to complete the approval',
  APPROVAL_PENDING_GROUP_DISABLED:
    'The approval for this device is pending, you can change a group while approving the device',
};

export const ANDROID_POLICY_MSG = {
  APP_CATALOG_INFOTEXT: {
    AUTO_UPDATE_DEFAULT_SETTING: 'Controls when automatic app updates are applied.',
    RUNTIME_PERMISSION_DEFAULT_SETTING: 'Specifies how app permissions are granted or denied.',

    UNTRUSTED_APPS_INSTALLATION:
      'Manages whether apps from unknown sources (untrusted apps) can be installed.',
    PLAYPROTECT_APPS_VERIFICATION:
      'Warning: Specifies if Google Play Protect verification is required for apps.',

    VPN_LOCKDOWN_EXEMPTION:
      'Controls whether an app is exempt from the always-on VPN lockdown setting.',
    WORK_PERSONAL_APP_CONNECT:
      'Seamlessly access work and personal profile content within a single interface. Requires user consent and admin approval.',
    WIDGET_ON_HOME_SCREEN: 'Allow work profile apps to add widgets to the personal home screen. ',
    UPDATE_MODE: 'Choose how and when updates to the app will be applied on the device.',
    APP_MIN_VERSION: 'Defines the minimum app version required for compatibility.',
    APP_CATALOG_TOOLTIP: {
      AUTO_UPDATE_DEFAULT_SETTING: {
        ALWAYS: 'Apps are auto-updated at any time. Data charges may apply.',
        NEVER: '	Apps are never auto-updated.',
        WIFI_ONLY: 'Apps are auto-updated over Wi-Fi only.',
        USER_CHOICE: 'The user can control auto-updates.',
      },
      INSTALL_TYPES: {
        FORCED:
          'The app is automatically installed and cannot be removed by the user, even during the maintenance window.',
        PRE: 'The app is automatically installed and can be removed by the user.',
        BLOCK:
          'The app cannot be installed, and if previously installed, it will be uninstalled (blocks instant app functionality).',
        AVAILABLE: 'The app is available to install.',
        KIOSK: `The app is installed in kiosk mode (locked home screen, status bar disabled) and can't be removed by the user. Only one app can have this install type per policy.`,
        // REQUIRED_FOR_SETUP: 'The app must be installed to complete setup and cannot be removed by the user.'
      },
      UNTRUSTED_APP_INSTALL: {
        ALLOW: 'Untrusted apps can be installed on the entire device.',
        DENY: 'Untrusted apps cannot be installed on the device (default).',
        ALLOW_IN_PERSONAL_PROFILE: `Untrusted apps can only be installed in the device's personal profile (for devices with work profiles). `,
      },
      PLAY_PROTECT_APP_VERIFY: {
        ALLOW: 'App verification is always enabled (default).',
        USER_CHOISE: 'he user can choose whether to enable or disable app verification.',
      },
    },
  },
  PASSWORD_SETTINGS_INFOTEXT: {
    SCOPE: 'The scope that the password requirement applies to.',
    QUALITY: 'The required complexity of the password.',
    PASSWORD_HISTORY: 'The number of previous passwords that cannot be reused.',
    PASSWORD_EXPIRY: 'The duration after which the password will expire and need to be updated.',
    MAX_FAIL_PASSWORDS:
      'Warning: The number of incorrect password attempts allowed before the device is wiped.',
    REQ_PASSWORD_UNLOCK:
      'Time allowed after unlocking a device or work profile with strong authentication (e.g., password, PIN) before weaker authentication (e.g., fingerprint) is allowed.',
    UNIFIED_LOCK_SETTINGS:
      'Determines whether a unified lock is allowed for the device and work profile.',

    UNIFIED_LOCK_SETTINGS_TOOLTIP: {
      COMMON_LOCK:
        'A common lock (single lock) for both the device and the work profile is allowed.',
      SEPERATE_LOCK: 'A separate lock for the work profile is required.',
    },
  },
  DEVICE_FUNCTIONALITY_INFOTEXT: {
    SCREEN_CAPTURE:
      'Controls whether screen capture (e.g., screenshots or screen recording) is allowed on the device.',
    SCREEN_RECORDING:
      'Controls whether screen capture (e.g., screenshots or screen recording) is allowed on the device.',
    MOUNT_PHYSICAL_MEDIA:
      'Controls whether the user is allowed to mount physical external media (e.g., SD cards, USB drives).',
    CAMERA_PERMISSION: `Controls camera usage and the user's ability to toggle camera access on the device.`,
    MICROPHONE:
      'Controls microphone usage and whether the user can toggle microphone access on fully managed devices (Android 12 and above).',
  },
  DATA_SHARING_INFOTEXT: {
    WORK_CONTACT_SCOPE:
      'Manages whether personal apps can access work profile contacts, including searches and incoming calls.',
    COPY_PASTE_SCOPE:
      'Controls whether text copied from one profile (personal or work) can be pasted into the other profile.',
    DATA_SHARING_SCOPE:
      'Controls whether data (e.g., web browsing, sharing content, opening documents) can be shared between profiles.',
    WIDGET_DEFAULT_SCOPE:
      'Controls whether work profile apps can add widgets to the home screen, in the absence of an app-specific policy.',
    USB_ACCESS:
      'Manages data transfer types via USB (charging is unaffected). Available only on company-owned devices.',
    BLUETOOTH: 'Controls whether Bluetooth is disabled on the device.',
    PRINTING:
      'Controls whether printing is allowed on the device. Supported on devices running Android 9 and above.',
  },
  SECURITY_INFOTEXT: {
    DEVELOPER_SETTING_SCOPE:
      'Warning: Manages access to developer settings, including developer options and safe boot.',
    MODIFY_ACCOUNT_DISABLED: 'Manages whether the user can add or remove accounts on the device.',
    DISABLE_ACCOUNT_TYPE: 'Account types that can not  be managed by the user.',
    KEYGUARD: 'Controls whether the lock screen is disabled on primary and/or secondary displays.',
    KEYGUARD_FEATURES: 'Controls which features of the keyguard (lock screen) can be disabled.',
    FACTORY_RESET: `Warning: Controls whether the user can perform a factory reset from the device's settings.`,

    SETTING_NAME: 'The setting that triggers the compliance rule',
    BLOCK_SCOPE: 'Specifies the scope of the BlockAction',
    BLOCK_AFTER_DAYS:
      'Number of days before the device or work profile is blocked for non-compliance.Set to 0 to block access immediately. Must be less than wipeAfterDays',
    WIPE_AFTER_DAYS:
      'Number of days before the device or work profile is wiped for non-compliance.(Must be greater than blockAfterDays)',

    SECURITY_TOOLTIP: {
      ALL: 'Disable all current and future keyguard customizations.',
      BIOMETRICS: 'Disable all biometric authentication on secure keyguard screens.',
      FACE: 'Disable face authentication on secure keyguard screens.',
      FINGERPRINT: 'Disable fingerprint sensor on secure keyguard screens.',
      IRIS: 'Disable iris authentication on secure keyguard screens.',
      CAMERA: 'Disable the camera on secure keyguard screens',
      NOTIFICATIONS: 'Disable showing all notifications on secure keyguard screens.',
      UNREDACTED_NOTIFICATIONS: 'Disable preview of notifications on secure keyguard screens.',
      REMOTE_INPUT:
        'On devices running Android 6 and below, disables text entry into notifications on secure keyguard screens. Has no effect on Android 7 and above.',
      TRUST_AGENTS: 'Ignore trust agent state on secure keyguard screens.',
      SHORTCUTS: 'Disable all shortcuts on secure keyguard screen on Android 14 and above.',
    },
  },
  OS_UPDATE_INFOTEXT: {
    SYSTEM_UPDATES: 'Defines the system update configuration settings for the device.',
    MAINTAINENCE_WINDOW:
      'Daily maintainence window during which the system updates will be scheduled.  If the maintenance window specified is smaller than 30 minutes, the actual window is extended to 30 minutes beyond the start time.',
    FREEZE_PERIOD: `During the freeze period, system updates (including security patches) are blocked and cannot be installed.`,
  },
  NETWORK_INFOTEXT: {
    VPN_CONFIG: 'Disables the ability for users to configure VPN settings on the device.',
    SELECT_VPN_APP: 'Only one app can be selected',
    ALLOW_NETWORK_WITHOUT_VPN: '',

    NETWORK_NAME: 'The name of the network configuration.',
    NETWORK_TYPE: 'Specifies the type of network configuration.',
    SSID: 'The Service Set Identifier (SSID) of the Wi-Fi network.',
    SECURITY: 'Specifies the security protocol used by the network.',
    PASSPHRASE: 'The password or passphrase used for network authentication.',
    EAP_INNER: 'Specifies the inner authentication protocol in EAP (e.g., MSCHAPv2, PAP)',
    EAP_OUTER:
      'Specifies the outer authentication protocol used in EAP (Extensible Authentication Protocol).',
    EAP_IDENTITY: 'The identity to use for EAP authentication.',
    EAP_DOMAIN_SUFFIX: 'A list of valid domain suffixes for the network.',
    EAP_SERVER_CA_REF: 'References to trusted server certificates.',
    EAP_CLIENT_CERT_TYPE: 'The type of client certificate used for authentication.',
    EAP_CLIENT_CERT_REF: 'A reference to a client certificate used for EAP authentication.',
    AUTO_CONNECT: 'Whether the device should automatically connect to this network.',
    RANDOM_MAC_ADDRESS_MODE:
      'Manages how the device randomizes its MAC address when connecting to the network.',

    NETWORK_TOOLTIP: {
      ADD_NETWORK_CONFIG_SECURITY: {
        WEP_PSK:
          'A legacy security protocol using a shared key (40-bit or 104-bit) for network authentication',
        WPA_PSK: 'Wi-Fi security using a pre-shared passphrase (8-63 characters)',
        WPA_EAP:
          'Includes settings specific to EAP (Extensible Authentication Protocol) for secure Wi-Fi networks.',

        RANDOM_MAC_ADDRESS_MODE_MENU: {
          HARDWARE: `Use the device's factory MAC address`,
          AUTOMATIC: 'The system selects the MAC address randomization method.',
        },
      },
    },
  },
  SUPORT_MSG_INFOTEXT: {
    SHORTSUPPORT_MESSAGE:
      'A message displayed to the user in the settings screen wherever functionality has been disabled by the admin.',
    LONGSUPPORT_MESSAGE:
      'A message displayed to the user in the device administators settings screen.',
  },

  COMMON_POLICY: {
    GRANT: 'The permission is automatically granted without user intervention.',
    PROMPT: 'The user will be asked to grant or deny the permission.',
    DENY: 'The permission is automatically denied.',
  },
  COMMON_SCOPES: {
    DEVICE_WIDE: 'Policy applies to entire device.',
    WORK_PROFILE: 'Policy applies only to the work profile (or similar).',
  },

  DEFAULT_POLICY_DELETION_NOT_ALLOWED: 'Default policy can not be deleted',

  SHORTSUPPORT_DEFAULT_MESSAGE: 'Blocked by your IT Admin.',
  LONGSUPPORT_DEFAULT_MESSAGE: 'Blocked by your IT Admin.',

  ADMIN_EMAIL_TOOLTIP: 'Email addresses of device administrators for factory reset protection.',
};

export const APPLE_ENROLLMENT_MSG = {
  PAGE_DISABLED_APNS: 'Please configure the Apple APNS Certificate to continue...',
  FILE_TYPE_INVALID: 'File type is not valid',
};

export const APPLE_DEVICE_MSG = {
  RETRY_MFA_TOOLTIP: `Available only for ${DeviceApprovalStagesEnum.mfa_requested} and ${DeviceApprovalStagesEnum.mfa_denied} stages.`,
};

export const APPLE_PROFILE_MSG = {
  PROFILE_NAME_REQUIRED: 'Profile name is required',
};

export const APPLE_APPLICATION_MSG = {
  FILE_TYPE_INVALID: 'File type is not valid',
};

export const USER_MSG = {
  USERS_INVALID_FILE_FORMAT: 'Please upload a valid Excel file (.xls, .csv or .xlsx).',
  USERS_FILE_FORMAT:
    'Incorrect file format. The file must have "Email" and "Username" as the first two columns.',
  USER_FILE_WORKSHEET: 'Worksheet not found in the uploaded file.',
};
