/* eslint-disable react/jsx-props-no-spreading */
import { IconButton, Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface IProps {
  infoText: string;
}

function Tooltip(props: TooltipProps) {
  const { title, children, ...restProps } = props;

  return (
    <MuiTooltip title={title} {...restProps}>
      {children}
    </MuiTooltip>
  );
}

function InfoTooltip(props: IProps) {
  const { infoText, ...restProps } = props;

  const isInfoStartWithWarning = (infoText: string) => {
    const words = infoText.trim().split(/\s+/);
    return words[0].toLowerCase() === 'warning:';
  };
  const tooltipColor = isInfoStartWithWarning(infoText) ? '#cc0000' : 'grey';

  return (
    <MuiTooltip title={infoText} placement='right' {...restProps}>
      <IconButton size='small' sx={{ width: 15, height: 15, color: tooltipColor }}>
        <InfoIcon fontSize='small' />
      </IconButton>
    </MuiTooltip>
  );
}

export default InfoTooltip;
export { Tooltip };
