import { Grid, SelectChangeEvent } from '@mui/material';
import { useContext } from 'react';
import { PolicyContext } from '../../../..';
import DeviceProfileType from '../../../../constants';
import PropertyAndSelectInput from '../../../PropertyAndSelectInput';
import { ANDROID_POLICY_MSG } from '../../../../../../messages';

function AppSecurity() {
  const { policy, setPolicy } = useContext(PolicyContext);

  const { untrustedApps, playProtectVerifyApps } = policy.appCatalog;

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      appCatalog: {
        ...prev.appCatalog,
        [name]: value,
      },
    }));
  };

  const propertyList = [
    {
      property: 'Untrusted Apps Installation',
      name: 'untrustedApps',
      infoText: ANDROID_POLICY_MSG.APP_CATALOG_INFOTEXT.UNTRUSTED_APPS_INSTALLATION,
      menuItems: [
        { view: 'Allow', value: 'allow_device_wide' },
        { view: 'Deny', value: 'disallowed' },
        { view: 'Allow Only in Personal Profile', value: 'allow_personal_profile_only' },
      ],
      value: untrustedApps,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
    {
      property: 'PlayProtect Apps Verification',
      name: 'playProtectVerifyApps',
      infoText: ANDROID_POLICY_MSG.APP_CATALOG_INFOTEXT.PLAYPROTECT_APPS_VERIFICATION,
      menuItems: [
        { view: 'Allow', value: 'enforced' },
        { view: 'User Choice', value: 'user_choice' },
      ],
      value: playProtectVerifyApps,
      androidVersion: '5.0+',
      profileType: `${DeviceProfileType.BYOD}, ${DeviceProfileType.COMPANY_OWNED}`,
      handleChange,
    },
  ];

  return (
    <Grid container justifyContent='space-between' spacing={2}>
      <Grid item sm={6}>
        {propertyList.map((item) => (
          <PropertyAndSelectInput key={`${item.property} ${item.name}`} data={item} />
        ))}
      </Grid>
    </Grid>
  );
}
export default AppSecurity;
