import { useContext, useState, useEffect, ChangeEvent } from 'react';
import { Button, SelectChangeEvent } from '@mui/material'; // Import MUI Button
import { ProfileContext } from '../../..';
import PropertyAndInput from '../../../../AndroidPolicies/components/PropertyAndInput';
import PropertyAndSelectInput from '../../../../AndroidPolicies/components/PropertyAndSelectInput';
import { IWifi } from '../../../definitions';

const initWifi: IWifi = {
  uuid: '',
  identifier: '',
  ssid: '',
  autoJoin: true,
  encryptionType: '',
  hiddenNetwork: true,
  MACrandomization: true,
  password: '',
  proxyType: 'None', // Default to None
  proxyServer: '',
  proxyServerPort: 0, // Default to 0
  proxyUsername: '',
  proxyPassword: '',
  proxyPACURL: '',
};

function Wifi() {
  const { profile, setProfile } = useContext(ProfileContext);
  const [wifiConfigs, setWifiConfigs] = useState<IWifi[]>(profile.wifi || []);

  const EncryptionMenuItems = [
    { view: 'Any', value: 'Any' },
    { view: 'WEP', value: 'WEP' },
    { view: 'WPA', value: 'WPA' },
    { view: 'WPA2', value: 'WPA2' },
    { view: 'WPA3', value: 'WPA3' },
  ];

  const booleanMenuItems = [
    { view: 'Allow', value: true },
    { view: 'Deny', value: false },
  ];

  const addWifiConfig = () => {
    setWifiConfigs([...wifiConfigs, { ...initWifi }]);
  };

  const handleInputChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | number>,
  ) => {
    const { value, name } = e.target || {};

    setWifiConfigs((prevConfigs) =>
      prevConfigs.map((config, i) => {
        if (i === index) {
          return {
            ...config,
            [name]: value,
          };
        }
        return config;
      }),
    );
  };

  const removeWifiConfig = (index: number) => {
    const updatedConfigs = [...wifiConfigs];
    updatedConfigs.splice(index, 1);
    setWifiConfigs(updatedConfigs);
  };

  useEffect(() => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      wifi: wifiConfigs,
    }));
  }, [wifiConfigs, setProfile]);

  return (
    <div>
      {wifiConfigs.map((config, index) => (
        <div
          style={{
            marginBottom: '16px',
            border: '1px solid #ccc',
            padding: '16px',
            borderRadius: '8px',
          }}
        >
          <PropertyAndInput
            property='SSID'
            name='ssid'
            value={config.ssid}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e)}
            isRequired
            placeholder='Network Name'
          />
          <PropertyAndInput
            property='Password'
            name='password'
            value={config.password}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e)}
            isRequired
            placeholder='Password'
          />
          <PropertyAndSelectInput
            data={{
              property: 'Encryption',
              name: 'encryptionType',
              menuItems: EncryptionMenuItems,
              value: config.encryptionType,
              handleChange: (e: SelectChangeEvent<string>) => handleInputChange(index, e),
              isRequired: true,
              selectFieldLabel: 'Encryption Type',
            }}
          />
          <PropertyAndSelectInput
            data={{
              property: 'Auto Join',
              name: 'autoJoin',
              menuItems: booleanMenuItems,
              value: config.autoJoin,
              handleChange: (e: SelectChangeEvent<string>) => handleInputChange(index, e),
              isRequired: false,
              selectFieldLabel: 'Auto Join',
            }}
          />
          <PropertyAndSelectInput
            data={{
              property: 'Hidden Network',
              name: 'hiddenNetwork',
              menuItems: booleanMenuItems,
              value: config.hiddenNetwork,
              handleChange: (e: SelectChangeEvent<string>) => handleInputChange(index, e),
              isRequired: false,
              selectFieldLabel: 'Hidden Network',
            }}
          />
          <PropertyAndSelectInput
            data={{
              property: 'MAC Randomization',
              name: 'MACrandomization',
              menuItems: booleanMenuItems,
              value: config.MACrandomization,
              handleChange: (e: SelectChangeEvent<string>) => handleInputChange(index, e),
              isRequired: false,
              selectFieldLabel: 'MAC Randomization',
            }}
          />
          <PropertyAndSelectInput
            data={{
              property: 'Proxy Type',
              name: 'proxyType',
              menuItems: [
                { view: 'None', value: 'None' },
                { view: 'Manual', value: 'Manual' },
                { view: 'Auto', value: 'Auto' },
              ],
              value: config.proxyType,
              handleChange: (e: SelectChangeEvent<string>) => handleInputChange(index, e),
              isRequired: false,
              selectFieldLabel: 'Proxy Type',
            }}
          />
          {config.proxyType === 'Manual' && (
            <>
              <PropertyAndInput
                property='Proxy Server'
                name='proxyServer'
                value={config.proxyServer}
                onChange={(e) => handleInputChange(index, e)}
                placeholder='Enter Proxy Server'
              />
              <PropertyAndInput
                property='Proxy Server Port'
                name='proxyServerPort'
                value={config.proxyServerPort}
                onChange={(e) => handleInputChange(index, e)}
                type='number'
                placeholder='Enter Proxy Server Port'
              />
              <PropertyAndInput
                property='Proxy Username'
                name='proxyUsername'
                value={config.proxyUsername}
                onChange={(e) => handleInputChange(index, e)}
                placeholder='Enter Proxy Username'
              />
              <PropertyAndInput
                property='Proxy Password'
                name='proxyPassword'
                value={config.proxyPassword}
                onChange={(e) => handleInputChange(index, e)}
                type='password'
                placeholder='Enter Proxy Password'
              />
            </>
          )}
          {config.proxyType === 'Auto' && (
            <PropertyAndInput
              property='Proxy PAC URL'
              name='proxyPACURL'
              value={config.proxyPACURL}
              onChange={(e) => handleInputChange(index, e)}
              placeholder='Enter PAC URL'
            />
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
            <Button
              onClick={() => removeWifiConfig(index)}
              style={{ backgroundColor: 'red', color: 'white', marginLeft: '8px' }}
              variant='contained'
            >
              Delete
            </Button>
          </div>
        </div>
      ))}
      <Button variant='contained' onClick={addWifiConfig}>
        Add Network Configuration
      </Button>
    </div>
  );
}

export default Wifi;
