import appleicon from '../../../assets/images/apple-logo.png';
import { Tooltip } from '../../AndroidPolicies/components/InfoTooltip';

interface IProps {
  appleVersion: string;
}

function AppleMinimumVersion(props: IProps) {
  const { appleVersion } = props;
  return (
    <Tooltip title={`${appleVersion} and above`}>
      <img src={appleicon} alt='Minimum apple Version Supported' width={15} height={15} />
    </Tooltip>
  );
}

export default AppleMinimumVersion;
