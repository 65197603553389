/* eslint-disable react/jsx-props-no-spreading */
import { DataGrid } from '@mui/x-data-grid';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function CustomTable(props: any) {
  const { rows, columns, ...restProps } = props;
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      rowHeight={45}
      columnHeaderHeight={45}
      sx={{
        p: 2,
        pt: 0,
        maxHeight: 566,
      }}
      {...restProps}
    />
  );
}

export default CustomTable;
