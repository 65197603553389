import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { ANDROID_DEVICE_CONST, ANDROID_POLICY_CONST } from '../../../constants';
import { WipeDataFlagsEnum } from '../definitions';
import AndroidMinimumVersionTooltip from '../../AndroidPolicies/components/AndroidMinimumVersion';
import ProfileType from '../../AndroidPolicies/components/ProfileType';
import DeviceProfileType from '../../AndroidPolicies/constants';
import { Tooltip } from '../../AndroidPolicies/components/InfoTooltip';

interface IProps {
  wipingMessage: string;
  deviceManagementMode: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setWipingMessage: React.Dispatch<React.SetStateAction<string>>;
  handleDeviceDeletion: (wipeFlags: WipeDataFlagsEnum[]) => void;
}

function DeprovisionDialog(props: IProps) {
  const {
    wipingMessage,
    open,
    setOpen,
    setWipingMessage,
    deviceManagementMode,
    handleDeviceDeletion,
  } = props;
  const [preserveFrpdata, setPreserveFrpdata] = useState<boolean>(false);
  const [wipeStorage, setWipeStorage] = useState<boolean>(false);

  const handleDeprovision = () => {
    const wipeFlags: WipeDataFlagsEnum[] = [];

    if (preserveFrpdata) {
      wipeFlags.push(WipeDataFlagsEnum.PRESERVE_RESET_PROTECTION_DATA);
    }
    if (wipeStorage) {
      wipeFlags.push(WipeDataFlagsEnum.WIPE_EXTERNAL_STORAGE);
    }
    handleDeviceDeletion(wipeFlags);
    setOpen(false);
  };
  const maxLength = ANDROID_POLICY_CONST.USER_FACING_MESSAGE_MAXLENGTH;
  const helperText = `${wipingMessage.length}/${maxLength} characters`;
  const errorInTextfield = wipingMessage.length > maxLength;

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      sx={{ height: '60vh' }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle>Deprovision</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography>Deprovision Message</Typography>
        <TextField
          sx={{ marginTop: 2 }}
          value={wipingMessage}
          onChange={(event) => setWipingMessage(event.target.value)}
          placeholder='Type here...'
          rows={4}
          fullWidth
          multiline
          inputProps={{ maxLength }}
          helperText={helperText}
          error={errorInTextfield}
          FormHelperTextProps={{
            style: { color: errorInTextfield ? 'red' : 'inherit' },
          }}
        />
        <Tooltip
          title={deviceManagementMode === ANDROID_DEVICE_CONST.BYOD ? 'Not available for BYOD' : ''}
          placement='top'
        >
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preserveFrpdata}
                  onChange={(event) => setPreserveFrpdata(event.target.checked)}
                  color='primary'
                  disabled={deviceManagementMode === ANDROID_DEVICE_CONST.BYOD}
                />
              }
              label={
                <Box display='flex' alignItems='center' gap={1}>
                  <Typography>
                    Require admin email authentication after reset? This only works if admin email
                    is configured in the policy for this device.
                  </Typography>
                  <AndroidMinimumVersionTooltip androidVersion='6.0+' />
                  <ProfileType profileType={DeviceProfileType.COMPANY_OWNED} />
                </Box>
              }
              sx={{ marginTop: 2 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={wipeStorage}
                  onChange={(event) => setWipeStorage(event.target.checked)}
                  color='primary'
                  disabled={deviceManagementMode === ANDROID_DEVICE_CONST.BYOD}
                />
              }
              label={
                <Box display='flex' alignItems='center' gap={1}>
                  <Typography>
                    Additionally wipe the devices external storage ( such as SD cards ).
                  </Typography>
                  <AndroidMinimumVersionTooltip androidVersion='6.0+' />
                  <ProfileType profileType={DeviceProfileType.COMPANY_OWNED} />
                </Box>
              }
              sx={{ marginTop: 2 }}
            />
          </Box>
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button variant='contained' color='error' onClick={handleDeprovision}>
          Deprovision
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeprovisionDialog;
